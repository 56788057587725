import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider, IconButton, Snackbar, Typography, Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FhxViewReportButton from './FhxViewReportButton';
import FhxQuestionCommentary from './FhxQuestionCommentary';
import FhxRestartButton from './FhxRestartButton';

export default function FhxTestDriveControls({ mkey, questionNumber, setContent }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const showSnackbarMessage = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleClose = () => setSnackbarOpen(false);

  const snackbarEndAction = (
    // renders at the end (right side) of the snackbar
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <>
      <Grid
        item
        alignItems="center"
        display="flex"
        direction="column"
      >
        <Typography variant="h1" sx={{ my: 1 }} textAlign="center">
          Your Comments & Controls
        </Typography>
        <Divider style={{ width: '100%' }} />
        <FhxQuestionCommentary
          mkey={mkey}
          questionNumber={questionNumber}
          showSnackbarMessage={showSnackbarMessage}
        />
        <Divider style={{ width: '100%' }} />
        <FhxViewReportButton
          mkey={mkey}
          questionNumber={questionNumber}
          showSnackbarMessage={showSnackbarMessage}
        />
        <Divider style={{ width: '100%' }} />
        <FhxRestartButton
          mkey={mkey}
          setContent={setContent}
        />
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbarMessage}
        action={snackbarEndAction}
      />
    </>
  );
}

FhxTestDriveControls.propTypes = {
  mkey: PropTypes.string.isRequired,
  questionNumber: PropTypes.number.isRequired,
  setContent: PropTypes.func.isRequired,
};
