export default function mapleTheme() {
  const primaryColor = 'rgb(175, 46, 23, 1)';
  const primaryActiveColor = 'rgba(175, 46, 23, 0.88)';
  const primaryHoverColor = 'rgba(175, 46, 23, 0.88)';
  const disabledColor = 'rgba(27, 27, 27, 0.12)';
  const primarySelectedColor = 'rgb(116, 82, 121)';
  const borderColor = 'rgb(161, 161, 161, 1)';
  const fullRoundedBorderRadius = '999px';
  const outlineColor = 'rgba(119, 119, 119, 1)';
  const primaryOutlineColor = '#1B1B1B';
  return {
    radioButtonIcon: 'filledCircle',
    radioButtonStyle: 'transparent',
    checkBoxStyle: 'transparent',
    contentButtonStyle: 'fullWidth',
    typography: {
      fontFamily: 'moderatregular,sans-serif',
      color: primaryOutlineColor,
      h1: {
        fontWeight: 400,
        fontSize: '32px',
        lineHeight: '38px',
        fontFamily: 'ennoregular,serif',
        fontStyle: 'normal',
      },
      h2: {
        fontWeight: 500,
        fontSize: '1.125rem',
        lineHeight: '1.4',
        fontFamily: 'ennoregular,serif',
      },
      body1: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
    palette: {
      primary: {
        main: primaryColor,
      },
      border: {
        main: borderColor,
      },
      // For slider background color only for now
      background: {
        main: '#d0d0d0',
        secondary: '#e6e6e605',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'capitalize',
            '&.MuiButton-containedPrimary:not(.Mui-disabled)': {
              backgroundColor: primaryColor,
              color: 'white',
              border: `0.5px solid ${primaryColor}`,
              padding: '8px 32px',
              borderRadius: fullRoundedBorderRadius,
              whiteSpace: 'nowrap',
              boxShadow: 'none',
              '&:active': {
                backgroundColor: `${primaryActiveColor} !important`,
                color: 'white',
              },
              '&.Mui-selected': {
                backgroundColor: `${primaryActiveColor} !important`,
                color: 'white',
                border: `0.5px solid ${primaryActiveColor}`,
              },
              '&:hover': {
                backgroundColor: primaryHoverColor,
                color: 'white',
                border: `0.5px solid ${primaryActiveColor}`,
              },
            },
            '&.MuiButton-containedPrimary.Mui-disabled': {
              backgroundColor: disabledColor,
              color: 'white',
              padding: '8px 32px',
              borderRadius: fullRoundedBorderRadius,
            },
            '&.MuiButton-outlinedPrimary:not(.Mui-disabled)': {
              textTransform: 'capitalize',
              color: primaryOutlineColor,
              border: 'none',
              padding: '8px 0',
              minWidth: '36px',
              width: 'auto',
              whiteSpace: 'nowrap',
              justifyContent: 'center',
              '&:hover': {
                color: primaryHoverColor,
                backgroundColor: 'transparent',
              },
            },
            '&.MuiButton-outlinedSecondary:not(.Mui-disabled)': {
              textTransform: 'capitalize',
              color: primaryOutlineColor,
              border: `1px solid ${primaryOutlineColor}`,
              borderRadius: fullRoundedBorderRadius,
              padding: '8px 32px',
              minWidth: 'auto',
              justifyContent: 'center',
            },
            '&.MuiButton-outlinedSecondary.Mui-disabled': {
              color: disabledColor,
              minWidth: 'auto',
              borderRadius: fullRoundedBorderRadius,
              padding: '8px 32px',
              justifyContent: 'center',
            },
            '&:focus': {
              outline: `2px solid ${outlineColor}`,
            },
            '&:focus:not(:focus-visible)': {
              outline: 'none',
            },
          },
        },
      },
      MuiStack: {
        styleOverrides: {
          root: {
            flexDirection: 'column-reverse !important',
            width: '100%',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            color: 'black',
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'space-between',
            color: primaryOutlineColor,
            margin: ' 0px 0px 12px 0px !important',
            padding: '5px 10px',
            minHeight: '40px',
            borderRadius: `${fullRoundedBorderRadius} !important`,
            border: `0.5px solid ${primaryOutlineColor} !important`,
            '&.Mui-selected': {
              backgroundColor: `${primarySelectedColor} !important`,
              color: 'white',
              border: `0.5px solid ${primarySelectedColor} !important`,
            },
            '&:hover': {
              backgroundColor: 'transparent !important',
            },
            '&:hover.Mui-selected': {
              backgroundColor: `${primarySelectedColor} !important`,
              color: 'white',
              border: `0.5px solid ${primarySelectedColor} !important`,
            },
            '&:focus': {
              outline: `2px solid ${outlineColor}`,
            },
            '&:focus:not(:focus-visible)': {
              outline: 'none',
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '0px auto',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            color: primarySelectedColor,
            padding: '0px',
            margin: '6px',
            borderRadius: fullRoundedBorderRadius,
            border: '0.5px transparent',
            '&:hover': {
              backgroundColor: primarySelectedColor,
              color: 'white',
            },
            '&:focus': {
              outline: `2px solid ${outlineColor}`,
            },
            '&:focus:not(:focus-visible)': {
              outline: 'none',
            },
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            borderRadius: fullRoundedBorderRadius,
            padding: '8px 32px',
          },
        },
      },
      MuiFormGroup: {
        styleOverrides: {
          root: {
            gap: '0 !important',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '0 auto',
            padding: '6px 16px',
            border: '0.5px solid #000',
            minHeight: '44px',
            width: '100%',
            marginTop: '-0.5px',
            backgroundColor: 'transparent',
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            transform: 'scale(0.75)',
            padding: '0px',
            '&.Mui-checked': {
              color: primarySelectedColor,
            },
            '&.Mui-focusVisible': {
              outline: `2px solid ${outlineColor}`,
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            transform: 'scale(0.75)',
            padding: '0px',
            '&.Mui-checked': {
              color: primarySelectedColor,
            },
            '&.Mui-focusVisible': {
              outline: `2px solid ${outlineColor}`,
              borderRadius: '2px',
            },
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            minHeight: '80%', // Apply to all containers
          },
        },
      },
    },
  };
}
