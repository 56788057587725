import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

function FhxBodyMapControlToggle({
  isImageMode,
  handleViewModeToggle,
}) {
  const { t } = useTranslation();

  const customToggleButtonStyles = {
    border: '0 !important',
    backgroundColor: 'white',
    '&:active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.05)',
    },
  };

  return (
    <ToggleButtonGroup
      exclusive
      aria-label="text or body map options"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        width: 'fit-content',
        marginRight: 0,
        padding: '3px',
        background: '#d4d4d44a',
        marginBottom: '10px',
      }}
    >
      <ToggleButton
        data-testid="text-view-toggle"
        value="text"
        selected={!isImageMode}
        onClick={handleViewModeToggle}
        sx={{
          ...customToggleButtonStyles,
          margin: '0 2px 0 0 !important',
          borderRadius: '4px 0 0 4px !important',
        }}
      >
        {t('showTextOptions')}
      </ToggleButton>
      <ToggleButton
        data-testid="body-map-toggle"
        value="map"
        selected={isImageMode}
        onClick={handleViewModeToggle}
        sx={{
          ...customToggleButtonStyles,
          margin: '0 !important',
          borderRadius: '0 4px 4px 0 !important',
        }}
      >
        {t('showBodyMap')}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

FhxBodyMapControlToggle.propTypes = {
  handleViewModeToggle: PropTypes.func.isRequired,
  isImageMode: PropTypes.bool.isRequired,
};

export default FhxBodyMapControlToggle;
