import axios from 'axios';

const formatResponse = (response) => {
  if (response.status === 200) return { result: response };
  return { result: undefined, error: response.data };
};
export default class MedicalContentService {
  constructor() {
    this.client = null;
    this.api_url = process.env.REACT_APP_MEDICAL_CONTENT_ENDPOINT;
  }

  init() {
    this.api_token = null; // TODO: get access token from cookie
    const headers = {
      Accept: 'application/json',
    };
    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }
    this.client = axios.create({ baseURL: this.api_url, timeout: 30000, headers });
    return this.client;
  }

  async getAllLookupNames() {
    const result = await this.init().get('/medication/getAllLookupNames');
    return formatResponse(result);
  }

  async getCommonDirectionsById(id) {
    const result = await this.init().get(`/medication/getCommonDirectionsById/${id}`);
    return formatResponse(result);
  }

  async getAllRoutes() {
    const result = await this.init().get('/medication/getAllRoutes');
    return formatResponse(result);
  }

  async getDosageFormByRouteId(routeId) {
    const result = await this.init().get(`/medication/getDosageFormByRouteId/${routeId}`);
    return formatResponse(result);
  }

  async getUnitsByDosageFormId(dosageFormId) {
    const result = await this.init().get(`/medication/getUnitsByDosageFormId/${dosageFormId}`);
    return formatResponse(result);
  }
}
