import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, ToggleButtonGroup, ToggleButton, Container, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Controls } from '../../../common/Controls';
import FhxCheckboxGroup from '../../FhxCheckboxGroup';
import medicationShape from '../medSharedPropTypes';

function FrequencyOfUseUsingManualEntry({
  selectedMedication,
  handleMedicationUpdate,
}) {
  const { t, i18n } = useTranslation();

  const isRTL = i18n.dir() === 'rtl';
  const frequencyOptions = ['once daily', 'twice daily', 'three times daily'];
  const [isFrequencyKnown, setIsFrequencyKnown] = useState(true);

  const selectArrowIconVisible = isRTL ? <KeyboardDoubleArrowLeftIcon style={{ marginLeft: '2px', fontSize: '85%' }} />
    : <KeyboardDoubleArrowRightIcon style={{ marginRight: '2px', fontSize: '85%' }} />;

  const selectArrowIconHidden = isRTL
    ? (
      <KeyboardDoubleArrowLeftIcon style={{
        marginLeft: '2px',
        fontSize: '85%',
        visibility: 'hidden',
      }}
      />
    )
    : (
      <KeyboardDoubleArrowRightIcon style={{
        marginRight: '2px',
        fontSize: '85%',
        visibility: 'hidden',
      }}
      />
    );

  const handleToggleChange = () => {
    // Toggle the isScheduledMed property between true and false
    handleMedicationUpdate('isScheduledMed', !selectedMedication.isScheduledMed);
  };

  const handleInputChange = (event, newValue) => {
    const adjustedValue = newValue || '';
    handleMedicationUpdate('frequency', adjustedValue);
  };

  const handleCheckboxChange = (event) => {
    const isKnown = !event.target.checked;
    setIsFrequencyKnown(isKnown);

    if (!isKnown) {
      handleMedicationUpdate('frequency', '');
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h2" gutterBottom>
        {`${t('medStageFrequency.title')} ${selectedMedication.lookupName} ${selectedMedication.strength !== 0 ? selectedMedication.strength : ''} ${selectedMedication.strengthUnit || ''}${t('questionMark')}`}
      </Typography>

      <Grid container spacing={2}>
        {/* Frequency Input */}
        <Grid item xs={12}>
          <Controls.AutocompleteInput
            options={frequencyOptions}
            getOptionLabel={(option) => option}
            value={selectedMedication.frequency || ''}
            handleInputChange={handleInputChange}
            handleOptionChange={handleInputChange}
            disabled={!isFrequencyKnown}
            fullWidth
            sx={{ bgcolor: isFrequencyKnown ? 'inherit' : '#f5f5f5' }}
            label={t('medStageFrequency.frequencyLabel')}
            name="frequency-input"
          />
        </Grid>
        {/* Unsure Frequency Checkbox */}
        <Grid item xs={12}>
          <FhxCheckboxGroup
            options={[{
              id: 0,
              displayText: t('medStageFrequency.unsureFrequency'),
            }]}
            selectedIDs={!isFrequencyKnown ? [0] : []}
            handleChoiceChange={handleCheckboxChange}
          />
        </Grid>
        {/* Toggle Button Group */}
        <Grid item xs={12}>
          <ToggleButtonGroup
            value={selectedMedication.isScheduledMed}
            exclusive
            onChange={handleToggleChange}
            color="primary"
          >
            <ToggleButton data-testid="takeRegBtn" value>
              <p style={{
                maxWidth: '70%',
                display: 'flex',
                alignItems: 'center',
                margin: '0px',
              }}
              >
                {selectedMedication.isScheduledMed ? selectArrowIconVisible : selectArrowIconHidden}
                {t('medStageFrequency.takeRegularly')}
              </p>
            </ToggleButton>
            <ToggleButton data-testid="takeWhenNeedBtn" value={false}>
              <p style={{
                maxWidth: '70%',
                display: 'flex',
                alignItems: 'center',
                margin: '0px',
              }}
              >
                {!selectedMedication.isScheduledMed
                  ? selectArrowIconVisible : selectArrowIconHidden}
                {t('medStageFrequency.takeWhenNeeded')}
              </p>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </Container>
  );
}

FrequencyOfUseUsingManualEntry.propTypes = {
  selectedMedication: medicationShape.isRequired,
  handleMedicationUpdate: PropTypes.func.isRequired,
};

export default FrequencyOfUseUsingManualEntry;
