import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'ar', 'fr_CA'], // TODO: add more languages
    backend: {
      loadPath: 'assets/locales/{{lng}}/translation.json',
    },
  });
