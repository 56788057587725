import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { Container, Box, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { FhxNavButtons } from '../common/FhxContentComponents';
import IntakeServices from '../services/IntakeServices';
import { Controls } from '../common/Controls';

const services = new IntakeServices();

function FhxSignature({
  html,
  inputRequired,
  backAllowed,
  setParentStateCB,
  id,
}) {
  const isMobile = useMediaQuery('(max-width:425px)');
  const contentRef = useRef(null);
  const [hasSignature, setHasSignature] = useState(false);
  const [hasConsented, setHasConsented] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const sigCanvasRef = useRef(null);

  const handleContinueClick = () => {
    setLoading(true);
    const payload = { dataUrl: hasConsented ? sigCanvasRef.current?.toDataURL() : 'Declined' };
    services.postIntake(global.mkey, id, payload)
      .then((response) => {
        setParentStateCB(response.result ? response.result.data.content : null);
        if (window.top !== window.self) {
          window.parent.postMessage('Activity', '*');
        }
      }).catch((error) => {
        setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
      }).finally(() => {
        setLoading(false);
      });
  };

  const clearSignature = () => {
    sigCanvasRef.current.clear();
    setHasSignature(false);
  };

  const handleBackClick = () => {
    services
      .getPreviousIntake(global.mkey, id)
      .then((response) => {
        setParentStateCB(
          response.result ? response.result.data.content : null,
        );
      })
      .catch(() => setParentStateCB(null));
  };

  const handleConsentChange = (newConsentState) => {
    setHasConsented(newConsentState);
    if (newConsentState === false) {
      handleContinueClick();
    }
  };

  const transformFirstHtmlElementToH1 = (htmlContent) => {
    if (!htmlContent) return null;
    const updatedHtml = htmlContent.replace(
      /<div>(.*?)<\/div>/, // matches the first <div> element
      `<h1 style="font-size: ${theme.typography.body1.fontSize};">$1</h1>`,
    );
    return DOMPurify.sanitize(updatedHtml);
  };

  // Use useEffect to set focus on the h1 element when the component mounts or html prop changes.
  // This improves accessibility by providing a clear starting point for screen readers,
  // indicating that new content has been loaded.
  useEffect(() => {
    if (contentRef.current) {
      const h1 = contentRef.current.querySelector('h1');
      if (h1) {
        h1.setAttribute('tabindex', '-1');
        h1.style.outline = 'none';
        h1.focus();
      }
    }
  }, [html]);

  return (
    <Container maxWidth="md" sx={{ paddingTop: 2, paddingBottom: 2 }}>
      <Box
        component="span"
        data-testid="bodyText"
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        sx={{
          display: 'block',
          margin: '50px',
        }}
        dangerouslySetInnerHTML={{
          __html: transformFirstHtmlElementToH1(html),
        }}
        ref={contentRef}
      />
      <Box
        sx={{
          display: hasConsented ? 'none' : 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          my: 4,
        }}
      >
        <Controls.Button
          variant="outlined"
          data-testid="decline-consent-btn"
          text={t('decline')}
          loadingPosition="start"
          onClick={() => handleConsentChange(false)}
          aria-label="Decline Consent"
        />
        <Controls.Button
          fontWeight="bold"
          data-testid="consent-btn"
          text={t('consent')}
          onClick={() => handleConsentChange(true)}
          aria-label="Consent"
        />
      </Box>
      <Box
        sx={{
          display: hasConsented ? 'flex' : 'none',
          flexDirection: 'column',
          margin: '48px auto',
          maxWidth: isMobile ? '300px' : '600px',
        }}
      >
        <Typography sx={{ m: 1 }} align="left" variant="h2" fontWeight="700">{t('signBelow')}</Typography>
        <SignatureCanvas
          onBegin={() => setHasSignature(true)}
          canvasProps={{
            width: isMobile ? 300 : 600,
            height: isMobile ? 150 : 200,
            style: { border: '1px solid #7e7f80', margin: '6px' },
            'aria-label': 'Signature Canvas',
            tabindex: 0,
          }}
          ref={sigCanvasRef}
        />
        <Controls.Button
          sx={{ width: 'fit-content', margin: 'auto' }}
          text={t('clear')}
          variant="text"
          onClick={clearSignature}
          aria-label="Clear"
        />
      </Box>
      <FhxNavButtons
        backAllowed={backAllowed}
        continueAllowed={!inputRequired || hasSignature}
        continueButtonCallback={handleContinueClick}
        backButtonCallback={handleBackClick}
        isLoading={loading}
      />
    </Container>
  );
}

FhxSignature.propTypes = {
  id: PropTypes.number.isRequired,
  html: PropTypes.string,
  inputRequired: PropTypes.bool,
  backAllowed: PropTypes.bool,
  setParentStateCB: PropTypes.func,
};
FhxSignature.defaultProps = {
  html: '',
  inputRequired: undefined,
  backAllowed: undefined,
  setParentStateCB: () => { },
};

export default FhxSignature;
