/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useRollbar } from '@rollbar/react';
import { useTranslation } from 'react-i18next';
import FhxError from './FhxError';
import FhxSelectOne from './FhxSelectOne';
import FhxConsent from './FhxConsent';
import FhxSelectAll from './FhxSelectAll';
import FhxText from './FhxText';
import FhxFeedback from './FhxFeedback';
import FhxTextForm from './FhxTextForm';
import FhxNumberFormTextBox from './FhxNumberFormTextBox';
import FhxNumberFormSlider from './FhxNumberFormSlider';
import FhxAgeForm from './FhxAgeForm';
import FhxMedicationForm from './medications/FhxMedicationForm';
import FhxLanguageSelectOne from './FhxLanguageSelectOne';
import FhxFileUpload from './FhxFileUpload';
import FhxDateOfBirth from './FhxDateOfBirth';
import FhxSignature from './FhxSignature';

function FhxQuestion({
  content, setContent, mkey, isTestDrive, setCustomLocalization, medicationFormType, language,
}) {
  let ret = <FhxError />;
  const rollbar = useRollbar();
  const { t } = useTranslation();
  if (!content) return ret;
  if (content === 'deactivated') {
    // TODO: make a graphic like FHxError, translate?
    return t('intakeDeactivated');
  }
  switch (content.type) {
    case 'languageSelectOne':
      ret = (
        <FhxLanguageSelectOne
          {...content}
          setParentStateCB={setContent}
          setCustomLocalization={setCustomLocalization}
        />
      );
      break;
    case 'selectOne':
      ret = (
        <FhxSelectOne
          {...content}
          setParentStateCB={setContent}
          isTestDrive={isTestDrive}
          key={content.type}
        />
      );
      break;
    case 'selectOneBodyPart':
      ret = (
        <FhxSelectOne
          {...content}
          setParentStateCB={setContent}
          isTestDrive={isTestDrive}
          key={content.type}
        />
      );
      break;
    case 'consent':
      ret = (
        <FhxConsent
          {...content}
          setParentStateCB={setContent}
          key={content.type}
        />
      );
      break;
    case 'selectAll':
      ret = (
        <FhxSelectAll
          {...content}
          setParentStateCB={setContent}
          isTestDrive={isTestDrive}
          key={content.type}
        />
      );
      break;
    case 'selectAllBodyParts':
      ret = (
        <FhxSelectAll
          {...content}
          setParentStateCB={setContent}
          isTestDrive={isTestDrive}
          key={content.type}
        />
      );
      break;
    case 'text':
      ret = <FhxText {...content} setParentStateCB={setContent} key={content.type} />;
      break;
    case 'feedback':
      ret = <FhxFeedback {...content} setParentStateCB={setContent} key={content.type} />;
      break;
    case 'freeTextForm':
      ret = (
        <FhxTextForm
          {...content}
          setParentStateCB={setContent}
          key={content.type}
          language={language}
        />
      );
      break;
    case 'numberForm':
      ret = <FhxNumberFormTextBox {...content} setParentStateCB={setContent} key={content.type} />;
      break;
    case 'numberFormSlider':
      ret = <FhxNumberFormSlider {...content} setParentStateCB={setContent} key={content.type} />;
      break;
    case 'ageForm':
      ret = <FhxAgeForm contentState={content} setParentStateCB={setContent} key={content.type} />;
      break;
    case 'dateOfBirth':
      ret = <FhxDateOfBirth {...content} setParentStateCB={setContent} key={content.type} />;
      break;
    case 'medicationForm':
      ret = (
        <FhxMedicationForm
          {...content}
          setParentStateCB={setContent}
          mkey={mkey}
          medicationFormType={medicationFormType}
          key={content.type}
        />
      );
      break;
    case 'fileUpload':
      ret = (
        <FhxFileUpload
          {...content}
          setParentStateCB={setContent}
          setCustomLocalization={setCustomLocalization}
        />
      );
      break;
    case 'freeTextRfv':
      ret = (
        <FhxTextForm
          {...content}
          setParentStateCB={setContent}
          key={content.type}
          characterLimit={150}
          rowCount={1}
          submitOnEnter
        />
      );
      break;
    case 'signature':
      ret = <FhxSignature {...content} setParentStateCB={setContent} key={content.type} />;
      break;
    default:
      rollbar.error(`Unknown question type: ${content.type}`);
      break;
  }

  return ret;
}

FhxQuestion.propTypes = {
  content: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
  setContent: PropTypes.func.isRequired,
  mkey: PropTypes.string.isRequired,
  isTestDrive: PropTypes.bool,
  setCustomLocalization: PropTypes.func.isRequired,
  medicationFormType: PropTypes.string,
  language: PropTypes.string.isRequired,
};

FhxQuestion.defaultProps = {
  content: null,
  isTestDrive: false,
  medicationFormType: '',
};

export default FhxQuestion;
