import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { Controls } from './Controls';

export default function ButtonWithModal(props) {
  const {
    buttonText, modalTitle, modalMaxWidth, modalIsOpen, onClick, onClose, children, variant,
  } = props;

  return (
    <>
      <Controls.Button
        variant={variant || 'contained'}
        onClick={onClick}
        sx={{ margin: '6px' }}
        text={buttonText}
      />

      <Modal
        isOpen={modalIsOpen}
        handleClose={onClose}
        title={modalTitle}
        maxWidth={modalMaxWidth}
      >
        {children}
      </Modal>
    </>
  );
}

ButtonWithModal.propTypes = {
  buttonText: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalMaxWidth: PropTypes.string,
  modalIsOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
};

ButtonWithModal.defaultProps = {
  modalMaxWidth: 'sm',
  variant: '',
};
