import React, { } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import FhxNumberFormTextBox from './FhxNumberFormTextBox';

function FhxAgeForm({
  contentState,
  setParentStateCB,
}) {
  const { t } = useTranslation();
  const validator = (values) => {
    const { value, unit } = values;
    if (unit === 'weeks' && value <= 3) return t('useDays');
    if (unit === 'months' && value <= 2) return t('useWeeks');
    if (unit === 'years' && value < 2) return t('useMonths');
    return '';
  };
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <FhxNumberFormTextBox
      {...contentState}
      subType="textBox"
      setParentStateCB={setParentStateCB}
      validator={validator}
    />
  );
}

FhxAgeForm.propTypes = {
  contentState: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
  setParentStateCB: PropTypes.func.isRequired,
};

FhxAgeForm.defaultProps = {
  contentState: null,
};

export default FhxAgeForm;
