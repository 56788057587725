/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  lazy, Suspense, useState, useEffect,
} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

function LoadingSpinner() {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '400px',
    }}
    >
      <CircularProgress />
    </div>
  );
}

function ErrorBoundary({ children, onRetry }) {
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const handleError = (event) => {
      event.preventDefault();
      setHasError(true);
    };

    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, []);

  if (hasError) {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        p: 2,
      }}
      >
        <Alert severity="error">
          {t('bodyMap.failedToLoad')}
        </Alert>
        <Button
          variant="contained"
          onClick={() => {
            setHasError(false);
            if (onRetry) onRetry();
          }}
        >
          {t('bodyMap.retry')}
        </Button>
      </Box>
    );
  }

  return children;
}

function FhxBodyMapLazyLoad(props) {
  const [key, setKey] = useState(0);

  const handleRetry = () => {
    // Force a re-render of the component by changing the key
    setKey((prevKey) => prevKey + 1);
  };
  const FhxBodyMap = lazy(() => import('./FhxBodyMap'));

  return (
    <ErrorBoundary onRetry={handleRetry}>
      <Suspense fallback={<LoadingSpinner />}>
        <FhxBodyMap key={key} {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}
export default FhxBodyMapLazyLoad;
