import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRollbar } from '@rollbar/react';
import { Controls } from '../../../common/Controls';
import MedicalContentService from '../../../services/MedicalContentService';
import { MIN_TEXT_FIELD_WIDTH } from '../../../common/Input';
import FhxMedicationListNameView from '../FhxMedicationListNameView';
import medicationShape from '../medSharedPropTypes';
import { removeGenericNamePrefix, extractPrefix } from '../medicationUtils';

const initialFieldValues = {
  id: null,
  lookupName: '',
  genericNamePrefix: '',
  strength: '',
  strengthUnit: '',
  quantity: 0,
  quantityUnit: '',
  dosageForm: { id: 0, dosageForm: '' },
  frequency: '',
  isScheduledMed: true,
  route: {
    id: 0,
    abbreviation: '',
    description: '',
    action: '',
  },
};

function FhxMedStageNameInput({
  setCurrentMedication,
  currentMedication,
  handleAddMedicationName,
  handleDeleteMedication,
  medications,
  nameInputValue,
  setNameInputValue,
}) {
  const { t } = useTranslation();
  const rollbar = useRollbar();
  const [nameList, setNameList] = useState([]);
  const [listOpen, setListOpen] = useState(false);
  const [inputError, setInputError] = useState('');

  const fetchMedications = async () => {
    const medicalContentService = new MedicalContentService();
    const response = await medicalContentService.getAllLookupNames();
    if (response.result) {
      setNameList(response.result.data);
    } else {
      rollbar.error('Failed to fetch medications', response.error);
    }
  };

  useEffect(() => {
    fetchMedications();
  }, []);

  const findSelectedMedication = (newInputValue) => {
    const cleanInputValue = removeGenericNamePrefix(newInputValue);
    return nameList.find(
      (medication) => removeGenericNamePrefix(medication.lookupName) === cleanInputValue,
    );
  };

  const setMedicationState = (lookupName, genericNamePrefix = '', medicationId = null) => {
    setCurrentMedication({
      ...initialFieldValues,
      lookupName,
      genericNamePrefix,
      id: medicationId,
    });
  };

  const handleChange = (newInputValue, reason) => {
    // 'input' is triggered when the user manually types something in the Autocomplete input field.
    if (reason === 'input') {
      const genericNamePrefix = extractPrefix(newInputValue);
      setNameInputValue(newInputValue);

      const selectedMedication = findSelectedMedication(newInputValue);
      setMedicationState(newInputValue, genericNamePrefix, selectedMedication?.medicationId);

      // Show name options only if input length is over 2 characters
      setListOpen(newInputValue.length >= 2);

    // 'reset' is triggered when an option is selected from the dropdown list (programmatic change).
    } else if (reason === 'reset') {
      const selectedOption = findSelectedMedication(newInputValue);

      if (selectedOption) {
        const storedPrefix = currentMedication.genericNamePrefix || '';
        const fullNameWithPrefix = `${storedPrefix}${selectedOption.lookupName}`;

        setMedicationState(selectedOption.lookupName, storedPrefix, selectedOption.medicationId);

        // let user sees their selected medication from db with the prefix they typed
        setNameInputValue(fullNameWithPrefix);
      }
    }
  };

  const resetInputState = () => {
    setNameInputValue('');
    setInputError('');
    setListOpen(false);
  };

  const onAddMedication = () => {
    if (nameInputValue.trim() !== '') {
      handleAddMedicationName();
      resetInputState();
    } else {
      setInputError(t('medicationForm.addMedicationNameErrorLabel'));
    }
  };

  const filterOptionsByUserInput = (availableOptions, state) => {
    // if user's input contains a generic prefix i.e. teva,
    // we want to remove it before comparing it to the available options
    const cleanUserInput = removeGenericNamePrefix(state.inputValue);

    return availableOptions.filter((option) => option.toLowerCase().includes(cleanUserInput));
  };

  const getFilteredMedicationNames = () => {
    const cleanUserInput = removeGenericNamePrefix(nameInputValue);

    return nameList
      .filter((medication) => removeGenericNamePrefix(
        medication.lookupName,
      ).startsWith(cleanUserInput))
      .map((medication) => medication.lookupName);
  };

  return (
    <Container maxWidth="md" sx={{ padding: '0 !important' }}>
      <FhxMedicationListNameView
        medications={medications}
        onDeleteMedication={handleDeleteMedication}
      />
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 1 }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={5}
          minWidth={MIN_TEXT_FIELD_WIDTH}
        >
          <Controls.AutocompleteInput
            options={getFilteredMedicationNames()}
            getOptionLabel={(option) => option}
            filterOptions={filterOptionsByUserInput}
            value={nameInputValue}
            onInputChange={(event, newInputValue, reason) => handleChange(newInputValue, reason)}
            open={listOpen}
            onClose={() => setListOpen(false)}
            error={!!inputError}
            helperText={inputError}
            fullWidth
          />

        </Grid>
        <Grid
          item
          xs={10}
          md={2.5}
          lg={2.5}
          xl={2.5}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Controls.Button
            data-testid="addMedicationButton"
            onClick={onAddMedication}
            size="large"
            color="primary"
            variant="outlined"
            fullWidth
            sx={{ justifyContent: 'center' }}
            text={t('medicationForm.addMedication')}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

FhxMedStageNameInput.propTypes = {
  medications: PropTypes.arrayOf(medicationShape).isRequired,
  setCurrentMedication: PropTypes.func.isRequired,
  currentMedication: medicationShape.isRequired,
  handleAddMedicationName: PropTypes.func.isRequired,
  handleDeleteMedication: PropTypes.func.isRequired,
  setNameInputValue: PropTypes.func.isRequired,
  nameInputValue: PropTypes.string.isRequired,
};

export default FhxMedStageNameInput;
