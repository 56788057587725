import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRollbar } from '@rollbar/react';
import {
  InputLabel, TextField, Grid,
} from '@mui/material';
import IntakeServices from '../../../services/IntakeServices';
import Button from '../../../common/Button';

const services = new IntakeServices();

export default function FhxQuestionCommentary({ mkey, questionNumber, showSnackbarMessage }) {
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const rollbar = useRollbar();

  const submitComment = async () => {
    try {
      setLoading(true);
      await services.submitCommentOnQuestion(mkey, questionNumber, comment);
      setComment('');
      showSnackbarMessage('Comment submitted');
    } catch (error) {
      rollbar.error(`Test Drive: Question comment submission failed: ${error}`);
      showSnackbarMessage('Question comment submission failed. Please notify the FirstHx team.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
    >
      <InputLabel
        sx={{
          mt: 1,
          color: 'text.primary',
        }}
        htmlFor="comment-field"
      >
        Comment on the current question:
      </InputLabel>
      <TextField
        id="comment-field"
        sx={{ mx: 3, mt: 1, width: '90%' }}
        rows={5}
        value={comment}
        onChange={(e) => {
          setComment(e.target.value);
        }}
        multiline
      />
      <Grid
        item
        alignItems="center"
        display="flex"
      >
        <Button
          sx={{ my: 2 }}
          text="Send Comment"
          onClick={submitComment}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}
FhxQuestionCommentary.propTypes = {
  mkey: PropTypes.string.isRequired,
  questionNumber: PropTypes.number.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};
