import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Box } from '@mui/material';
import { isEmpty, isNull } from 'lodash';
import { FhxTitle, FhxNavButtons } from '../common/FhxContentComponents';
import IntakeServices from '../services/IntakeServices';
import AdjustableSlider from '../common/AdjustableSlider';

const services = new IntakeServices();

function FhxNumberFormSlider({
  title,
  backAllowed,
  setParentStateCB,
  id,
  inputRequired,
  helperText,
  validator,
  minValue,
  maxValue,
  stepSize,
  sliderType,
}) {
  const [value, setValue] = useState('');
  const [contBtnState, setContBtnState] = useState(!inputRequired);
  const [indexOfSelectedUnit, setIndexOfSelectedUnit] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleContinueClick = () => {
    const numeric = {
      value: parseFloat(value),
    };
    if (!isNull(indexOfSelectedUnit)) {
      numeric.indexOfUnitChosen = indexOfSelectedUnit;
    }
    const invalidMessage = validator(numeric);
    if (invalidMessage) {
      setErrorMessage(invalidMessage);
      return;
    }
    setLoading(true);
    services
      .postIntake(global.mkey, id, {
        id,
        numeric,
      })
      .then((response) => {
        setValue('');
        setIndexOfSelectedUnit(null);
        setErrorMessage('');
        setContBtnState(!inputRequired);
        setParentStateCB(response.result ? response.result.data.content : null);
        if (window.top !== window.self) {
          window.parent.postMessage('Activity', '*');
        }
      }).catch((error) => {
        setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
      }).finally(() => {
        setLoading(false);
      });
  };

  const handleBackClick = () => {
    services
      .getPreviousIntake(global.mkey, id)
      .then((response) => {
        setParentStateCB(response.result ? response.result.data.content : null);
      })
      .catch((error) => {
        setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
      });
  };

  const handleSliderChange = (newValue) => {
    const numberToStringForm = newValue.toString();
    setValue(numberToStringForm);
    if (inputRequired) {
      setContBtnState(!isEmpty(numberToStringForm));
    }
  };

  useEffect(() => {
    setContBtnState(!inputRequired);
  }, [inputRequired]);

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
          py: 2,
        }}
      >
        <FhxTitle title={title} helperText={helperText} />
        <Box
          sx={{
            width: 'auto',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <AdjustableSlider
            sliderValue={minValue}
            minValue={minValue}
            maxValue={maxValue}
            identifier={id}
            sliderType={sliderType}
            stepSize={stepSize}
            onValueChange={handleSliderChange}
            errorMessage={errorMessage}
          />
        </Box>
      </Container>
      <FhxNavButtons
        backAllowed={backAllowed}
        continueAllowed={contBtnState}
        continueButtonCallback={handleContinueClick}
        backButtonCallback={handleBackClick}
        isLoading={loading}
      />
    </>
  );
}

FhxNumberFormSlider.propTypes = {
  title: PropTypes.string.isRequired,
  backAllowed: PropTypes.bool.isRequired,
  setParentStateCB: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  inputRequired: PropTypes.bool.isRequired,
  sliderType: PropTypes.oneOf(['gestationalAge', 'weight', 'height']),
  helperText: PropTypes.string,
  validator: PropTypes.func,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  stepSize: PropTypes.number,
};

FhxNumberFormSlider.defaultProps = {
  helperText: '',
  sliderType: '',
  validator: () => {},
  minValue: 0,
  maxValue: 200,
  stepSize: 1,
};

export default FhxNumberFormSlider;
