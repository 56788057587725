import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import enLocale from 'date-fns/locale/en-US';
import arSaLocale from 'date-fns/locale/ar-SA';

import FhxLoading from './FhxLoading';

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

function RtlWrapper({ children }) {
  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
}
RtlWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const localeMap = {
  en: enLocale,
  ar: arSaLocale,
};

function FhxLocalization(props) {
  const { children, theme } = props;
  const { i18n } = useTranslation();
  const newTheme = createTheme(theme, {
    direction: i18n.dir(),
  });
  document.body.setAttribute('dir', i18n.dir());
  const WrapperComponent = i18n.dir() === 'rtl' ? RtlWrapper : React.Fragment;

  return (
    <Suspense fallback={<FhxLoading />}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[i18n.language]}>
        <WrapperComponent>
          <ThemeProvider theme={newTheme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </WrapperComponent>
      </LocalizationProvider>
    </Suspense>
  );
}

FhxLocalization.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired,
};

export default FhxLocalization;
