import PropTypes from 'prop-types';
import { round } from 'lodash';

const CM_TO_INCHES = 2.54;
const INCHES_PER_FOOT = 12;
const KG_TO_LBS = 2.20462;
const DAYS_PER_WEEK = 7;

export function convertCmToFtInches(cm) {
  if (!cm) return { cm: 0, feet: 0, inches: 0 };
  const totalInches = cm / CM_TO_INCHES;
  const feet = Math.floor(totalInches / INCHES_PER_FOOT);
  const inches = Math.round(totalInches % INCHES_PER_FOOT);
  return { cm, feet, inches };
}

convertCmToFtInches.propTypes = {
  cm: PropTypes.number.isRequired,
};

export function convertKgToLbs(kg) {
  if (!kg) return { kg: 0, lbs: 0 };
  const lbs = round(kg * KG_TO_LBS, 1); // Rounds to 1 decimal place
  return { kg, lbs };
}

convertKgToLbs.propTypes = {
  kg: PropTypes.number.isRequired,
};

export function convertDaysToWeeksDays(days) {
  const weeks = Math.floor(days / DAYS_PER_WEEK);
  const remainingDays = days % DAYS_PER_WEEK;

  return [weeks, remainingDays];
}

convertDaysToWeeksDays.propTypes = {
  days: PropTypes.number.isRequired,
};
