import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Grid, Typography,
} from '@mui/material';
import { useRollbar } from '@rollbar/react';
import { useTranslation } from 'react-i18next';
import { Controls } from '../../../common/Controls';
import { MIN_TEXT_FIELD_WIDTH } from '../../../common/Input';
import MedicalContentService from '../../../services/MedicalContentService';
import FhxCheckboxGroup from '../../FhxCheckboxGroup';
import medicationShape from '../medSharedPropTypes';

function FhxMedStageQuantityInput({
  selectedMedication,
  handleMedicationUpdate,
}) {
  const { t } = useTranslation();
  const rollbar = useRollbar();

  const [unitOptions, setUnitOptions] = useState([]);
  const [isQuantityKnown, setIsQuantityKnown] = useState(true);

  const handleInputChange = (prop) => (event) => {
    const value = event?.target?.value;
    const processedValue = prop === 'quantity' ? (parseFloat(value) || 0) : value;
    handleMedicationUpdate(prop, processedValue);
  };

  const handleCheckboxChange = (prop) => (event) => {
    const isKnown = !event.target.checked;
    if (prop === 'quantity') {
      setIsQuantityKnown(isKnown);
    }
    if (!isKnown) {
      const defaultValue = prop === 'quantity' ? 0 : '';
      handleMedicationUpdate(prop, defaultValue);
    }
  };

  const fetchUnits = async () => {
    if (!selectedMedication.dosageForm.id) {
      return;
    }
    try {
      const medicalContentService = new MedicalContentService();
      const dosageFormId = selectedMedication.dosageForm.id;
      const { result } = await medicalContentService.getUnitsByDosageFormId(dosageFormId);
      if (result) {
        setUnitOptions(result.data);
      } else {
        rollbar.error('No result found in fetching quantity units.');
      }
    } catch (error) {
      rollbar.error('Error fetching quantity units', error);
    }
  };

  useEffect(() => {
    fetchUnits();
  }, [selectedMedication.route.id]);

  return (
    <Container maxWidth="md" sx={{ mt: 1 }}>
      <Typography variant="h2" gutterBottom>
        {`${t('medStageQuantity.pre-title')} ${selectedMedication.genericNamePrefix ?? ''}${selectedMedication.lookupName} ${selectedMedication.strength !== 0 ? selectedMedication.strength : ''} ${selectedMedication.strengthUnit || ''} ${t('medStageQuantity.post-title')}${t('questionMark')}` }
      </Typography>
      <Grid container spacing={2} justifyContent="center" sx={{ mt: 1 }}>
        {/* Quantity Input */}
        <Grid item xs={12} md={8} minWidth={MIN_TEXT_FIELD_WIDTH}>
          <Controls.Input
            name="quantity-input"
            label={t('medStageQuantity.quantityLabel')}
            value={selectedMedication.quantity !== 0 ? selectedMedication.quantity : ''}
            onChange={handleInputChange('quantity')}
            disabled={!isQuantityKnown}
            sx={{ bgcolor: isQuantityKnown ? 'inherit' : '#f5f5f5' }}
            fullWidth
          />
        </Grid>
        {/* Quantity Unit Input */}
        <Grid item xs={12} md={4} minWidth={MIN_TEXT_FIELD_WIDTH}>
          <Controls.AutocompleteInput
            options={unitOptions}
            getOptionLabel={(option) => option}
            value={selectedMedication.quantityUnit}
            disabled={!isQuantityKnown}
            handleInputChange={(event, newInputValue) => {
              handleMedicationUpdate('quantityUnit', newInputValue);
            }}
            handleOptionChange={(event, newValue) => {
              const adjustedValue = newValue || '';
              handleMedicationUpdate('quantityUnit', adjustedValue);
            }}
            fullWidth
            sx={{ bgcolor: isQuantityKnown ? 'inherit' : '#f5f5f5' }}
            label={t('medStageQuantity.quantityUnitLabel')}
            name="quantity-unit-input"
          />
        </Grid>
        {/* Unsure Quantity Checkbox */}
        <Grid item xs={12}>
          <FhxCheckboxGroup
            options={[{
              id: 0,
              displayText: t('medStageQuantity.unsureQuantity'),
            }]}
            selectedIDs={!isQuantityKnown ? [0] : []}
            handleChoiceChange={handleCheckboxChange('quantity')}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

FhxMedStageQuantityInput.propTypes = {
  selectedMedication: medicationShape.isRequired,
  handleMedicationUpdate: PropTypes.func.isRequired,
};

export default FhxMedStageQuantityInput;
