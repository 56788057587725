import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Box, Rating, TextField,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { FhxTitle, FhxNavButtons } from '../common/FhxContentComponents';
import IntakeServices from '../services/IntakeServices';

const services = new IntakeServices();

function StarRating({ onChange }) {
  const [rating, setRating] = useState(0);

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
    onChange(newValue);
  };

  return (
    <Rating
      name="star-rating"
      value={rating}
      onChange={handleRatingChange}
      max={5}
      sx={{ fontSize: '2.5rem' }}
    />
  );
}

StarRating.propTypes = {
  onChange: PropTypes.func.isRequired,
};

function FeedbackTextField({ onChange }) {
  const [text, setText] = useState('');
  const { t } = useTranslation();

  const handleTextChange = (event) => {
    setText(event.target.value);
    onChange(event.target.value);
  };

  return (
    <TextField
      label={t('feedbackQuestion.feedback_placeholder')}
      value={text}
      onChange={handleTextChange}
      fullWidth
      multiline
      rows={6}
      rowsMax={6}
      maxLength={400}
      inputProps={{
        'aria-label': 'Enter feedback',
      }}
    />
  );
}

FeedbackTextField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

function FhxFeedback({
  id,
  title,
  backAllowed,
  setParentStateCB,
  feedback,
  inputRequired,
}) {
  const { starRating, text } = feedback;
  const feedbackDataInitial = {
    starRating: 0,
    text: '',
  };
  const [feedbackData, setFeedbackData] = useState(feedbackDataInitial);
  const [loading, setLoading] = useState(false);
  const setStarRating = (newValue) => {
    const adjustedValue = newValue || 0;
    setFeedbackData((prev) => ({ ...prev, starRating: adjustedValue }));
  };

  const setTextFeedback = (newText) => {
    setFeedbackData((prev) => ({ ...prev, text: newText }));
  };

  const handleContinueClick = () => {
    setLoading(true);
    services.postIntake(global.mkey, id, { feedback: feedbackData }).then((response) => {
      setParentStateCB(response.result ? response.result.data.content : null);
      if (window.top !== window.self) {
        setTimeout(() => {
          window.parent.postMessage('IntakeEnded', '*');
        }, 5000);
      }
    }).catch((error) => {
      setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleBackClick = () => {
    services.getPreviousIntake(global.mkey, id).then((response) => {
      setParentStateCB(response.result ? response.result.data.content : null);
    }).catch((error) => {
      setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
    });
  };

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        <FhxTitle title={title} />
        {starRating && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              m: 2,
            }}
          >
            <StarRating onChange={setStarRating} />
          </Box>
        )}
        {text && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              my: 2,
            }}
          >
            <FeedbackTextField onChange={setTextFeedback} />
          </Box>
        )}
      </Container>
      <FhxNavButtons
        isLoading={loading}
        backAllowed={backAllowed}
        continueAllowed={!inputRequired}
        continueButtonCallback={handleContinueClick}
        backButtonCallback={handleBackClick}
      />
    </>
  );
}

FhxFeedback.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  backAllowed: PropTypes.bool.isRequired,
  setParentStateCB: PropTypes.func.isRequired,
  feedback: PropTypes.shape({
    starRating: PropTypes.bool.isRequired,
    text: PropTypes.bool.isRequired,
  }).isRequired,
  inputRequired: PropTypes.bool.isRequired,
};

export default FhxFeedback;
