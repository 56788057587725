import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Grid, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controls } from '../../../common/Controls';
import { MIN_TEXT_FIELD_WIDTH } from '../../../common/Input';
import FhxCheckboxGroup from '../../FhxCheckboxGroup';
import medicationShape from '../medSharedPropTypes';

function FhxMedStageStrengthInput({
  selectedMedication,
  handleMedicationUpdate,
}) {
  const { t } = useTranslation();
  const [isStrengthKnown, setIsStrengthKnown] = useState(true);
  // Get unit options from translation or use default values
  const unitsTranslation = t('medicationStrengthForm.unitOptions', { returnObjects: true });
  const unitOptions = (typeof unitsTranslation === 'object' && unitsTranslation !== null)
    ? Object.values(unitsTranslation)
    : ['mg', 'g', 'ug/ul', '%']; // Default values as a fallback

  const handleChange = (event) => {
    const { name, value } = event.target;
    handleMedicationUpdate(name, value);
  };

  const handleCheckboxChange = (e) => {
    const isKnown = !e.target.checked;
    setIsStrengthKnown(isKnown);
    if (!isKnown) {
      handleMedicationUpdate('strength', 0);
      handleMedicationUpdate('strengthUnit', '');
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 1 }}>
      <Typography variant="h2" gutterBottom>
        {`${t('medicationStrengthForm.whatIsTheStrength')} ${selectedMedication.genericNamePrefix ?? ''}${selectedMedication.lookupName}${t('questionMark')}`}
      </Typography>
      <Grid container spacing={2} justifyContent="center" sx={{ mt: 1 }}>
        <Grid item xs={12} sm={12} md={8} lg={4} minWidth={MIN_TEXT_FIELD_WIDTH}>
          <Controls.Input
            label={t('medicationStrengthForm.strength')}
            name="strength"
            value={selectedMedication.strength}
            onChange={handleChange}
            disabled={!isStrengthKnown}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2} minWidth={MIN_TEXT_FIELD_WIDTH}>
          <Controls.AutocompleteInput
            options={unitOptions}
            getOptionLabel={(option) => option}
            value={selectedMedication.strengthUnit}
            disabled={!isStrengthKnown}
            handleInputChange={(event, newInputValue) => {
              handleChange({ target: { name: 'strengthUnit', value: newInputValue } });
            }}
            handleOptionChange={(event, newValue) => {
              const adjustedValue = newValue || '';
              handleChange({ target: { name: 'strengthUnit', value: adjustedValue } });
            }}
            label={t('medicationStrengthForm.strengthUnit')}
            name="strength-unit-input"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FhxCheckboxGroup
            options={[{
              id: 0,
              displayText: t('medicationStrengthForm.unsureAboutStrength'),
            }]}
            selectedIDs={!isStrengthKnown ? [0] : []}
            handleChoiceChange={handleCheckboxChange}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

FhxMedStageStrengthInput.propTypes = {
  selectedMedication: medicationShape.isRequired,
  handleMedicationUpdate: PropTypes.func.isRequired,
};

export default FhxMedStageStrengthInput;
