import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  Container,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import { useTranslation } from 'react-i18next';

function FhxFaqDialog({ faq }) {
  const [dialogVisible, setDialogVisible] = useState(false);
  const { t } = useTranslation();

  let updatedFaq = [];

  if (Array.isArray(faq)) {
    updatedFaq = faq.filter(
      (item) => typeof item.question === 'string'
        && typeof item.answer === 'string'
        && item.question !== ''
        && item.answer !== '',
    );
  }

  return (
    updatedFaq.length > 0 ? (
      <>
        <Container
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            data-testid="iconButtonInvisible"
            sx={{
              visibility: 'hidden',
            }}
          >
            <HelpOutlinedIcon />
          </IconButton>
          <IconButton
            color="inherit"
            data-testid="iconButtonVisible"
            onClick={() => setDialogVisible(true)}
          >
            <HelpOutlinedIcon data-testid="mainFaqIcon" />
          </IconButton>
        </Container>
        <Dialog open={dialogVisible}>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5" data-testid="title">
              {t('faq')}
            </Typography>
            <IconButton aria-label="Close" onClick={() => setDialogVisible(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {updatedFaq.map((item) => (
              <Container key={item.id} data-testid={`choice${item.id}`}>
                <Typography
                  data-testid={`innerQuestion${item.id}`}
                  variant="h6"
                  gutterBottom
                  sx={{
                    margin: '20px 0px',
                  }}
                >
                  {item.question}
                </Typography>
                <Typography
                  data-testid={`innerAnswer${item.id}`}
                  variant="body1"
                  gutterBottom
                  sx={{
                    margin: '20px 0px',
                  }}
                >
                  {item.answer}
                </Typography>
                <Divider />
              </Container>
            ))}
          </DialogContent>
        </Dialog>
      </>
    ) : null
  );
}

FhxFaqDialog.propTypes = {
  faq: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default FhxFaqDialog;
