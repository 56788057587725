import Input from './Input';
import Select from './Select';
import AutocompleteInput from './AutocompleteInput';
import Button from './Button';

// eslint-disable-next-line import/prefer-default-export
export const Controls = {
  Input,
  AutocompleteInput,
  Select,
  Button,
};
