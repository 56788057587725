import PropTypes from 'prop-types';

const medicationShape = PropTypes.shape({
  id: PropTypes.number,
  lookupName: PropTypes.string,
  quantity: PropTypes.number,
  quantityUnit: PropTypes.string,
  dosageForm: PropTypes.shape({
    id: PropTypes.number,
    dosageForm: PropTypes.string,
  }),
  frequency: PropTypes.string,
  strength: PropTypes.string,
  strengthUnit: PropTypes.string,
  isScheduledMed: PropTypes.bool,
  route: PropTypes.shape({
    id: PropTypes.number,
    abbreviation: PropTypes.string,
    description: PropTypes.string,
    action: PropTypes.string,
  }),
  dosage: PropTypes.string,
  dose: PropTypes.string,
  freeTextDirection: PropTypes.string,
});

export default medicationShape;
