import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { FhxHelperTextButton, HelperTextModal } from '../common/FhxContentComponents';

function CheckboxOptionContent({ option, selectedIDs }) {
  const selectArrowIconVisible = (
    <CheckRoundedIcon style={{
      width: '18px',
      height: '18px',
      marginLeft: '2px',
      marginRight: '2px',
    }}
    />
  );

  const selectArrowIconHidden = (
    <CheckRoundedIcon style={{
      width: '18px',
      height: '18px',
      marginLeft: '2px',
      marginRight: '2px',
      visibility: 'hidden',
    }}
    />
  );

  return (
    <p
      data-testid={`innerTextOfIndex${option.id}`}
      style={{
        maxWidth: '70%',
        display: 'flex',
        alignItems: 'center',
        margin: '0px',
      }}
    >
      {selectedIDs?.includes(option.id)
        ? selectArrowIconVisible
        : selectArrowIconHidden}
      {option.displayText}
    </p>
  );
}

CheckboxOptionContent.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.number.isRequired,
    displayText: PropTypes.string.isRequired,
    helperText: PropTypes.string,
  }).isRequired,
  selectedIDs: PropTypes.arrayOf(PropTypes.number),
};

CheckboxOptionContent.defaultProps = {
  selectedIDs: null,
};

export default function FhxToggleButtonGroup({
  options, selectedID, handleChoiceChange,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState(null);

  const handleIconClick = (option) => (e) => {
    e.stopPropagation();
    setCurrentOption(option);
    setModalOpen(true);
  };

  return (
    <>
      <ToggleButtonGroup
        fullWidth
        orientation="vertical"
        color="primary"
        value={selectedID}
        exclusive
        onChange={handleChoiceChange}
      >
        {options.map((option) => (
          <div
            key={option.id}
            style={{
              position: 'relative',
            }}
          >
            <ToggleButton
              disableFocusRipple
              style={{
                width: '100%',
                zIndex: 0,
              }}
              data-testid={`choice${option.id}`}
              value={option.id}
            >
              <CheckboxOptionContent
                option={option}
                selectedIDs={typeof selectedID === 'number' ? [selectedID] : selectedID}
                handleIconClick={handleIconClick}
              />
            </ToggleButton>
            {option?.helperText && (
              <div style={{
                position: 'absolute',
                top: '0px',
                right: '0px',
                paddingBottom: '12px',
                height: '100%',
                display: 'flex',
                placeItems: 'center',
                paddingRight: '10px',
              }}
              >
                <FhxHelperTextButton
                  title={option.displayText}
                  onButtonClick={handleIconClick(option)}
                />
              </div>
            )}
          </div>
        ))}
      </ToggleButtonGroup>
      {currentOption?.helperText && (
        <HelperTextModal
          title={currentOption.displayText}
          helperText={currentOption.helperText}
          modalOpen={modalOpen}
          onCloseRequest={() => setModalOpen(false)}
        />
      )}
    </>
  );
}

FhxToggleButtonGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    displayText: PropTypes.string.isRequired,
    helperText: PropTypes.string,
  })).isRequired,
  selectedID: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  handleChoiceChange: PropTypes.func.isRequired,
};

FhxToggleButtonGroup.defaultProps = {
  selectedID: null,
};
