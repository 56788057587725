import React from 'react';
import { useSearchParams } from 'react-router-dom';
import PtInitiatedIntakePage from './intake/PtInitiatedIntakePage';
import IntakeApp from './intake/IntakeApp';

function App() {
  const [searchParams] = useSearchParams();
  global.mkey = searchParams.get('mkey');

  return (
    global.mkey ? <IntakeApp /> : <PtInitiatedIntakePage />
  );
}

export default App;
