import React from 'react';
import { Container, Box, CircularProgress } from '@mui/material';

function FhxLoading() {
  return (
    <Container
      className="bodyContainer"
      maxWidth="md"
      sx={{
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
      <Box
        component="span"
        m={15}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress data-testid="loading" />
      </Box>
    </Container>
  );
}

export default FhxLoading;
