/* eslint-disable max-len */
import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

function FhxPrivacyInformation() {
  const { t } = useTranslation();

  return (
    <Box tabIndex={0}>
      <Typography variant="h1" gutterBottom>
        {t('privacyComponent.aboutApplication.header')}
      </Typography>
      <Typography variant="body1" paragraph>
        {t('privacyComponent.aboutApplication.body')}
      </Typography>

      <Typography variant="h2" gutterBottom>
        {t('privacyComponent.privacyNotice.header')}
      </Typography>
      <Typography variant="body1" paragraph>
        {t('privacyComponent.privacyNotice.body')}
      </Typography>

      <Typography variant="h2" gutterBottom>
        {t('privacyComponent.collectionUseDisclosureHeader')}
      </Typography>

      <Typography variant="h2" gutterBottom>
        {t('privacyComponent.healthcareProvider.header')}
      </Typography>
      <Box component="ul">
        <Typography component="li" variant="body1">
          {t('privacyComponent.healthcareProvider.infoBecomesPartOfRecord')}
        </Typography>
        <Typography component="li" variant="body1">
          {t('privacyComponent.healthcareProvider.onlyStoreIdentifyingInfo')}
        </Typography>
        <Typography component="li" variant="body1">
          {t('privacyComponent.healthcareProvider.providerKeepsHistoryUpdated')}
        </Typography>
      </Box>

      <Typography variant="body1" gutterBottom>
        {t('privacyComponent.firstHx.header')}
      </Typography>
      <Box component="ul">
        <Typography component="li" variant="body1">
          {t('privacyComponent.firstHx.useInfoForImprovement')}
        </Typography>
        <Typography component="li" variant="body1">
          {t('privacyComponent.firstHx.notStorePersonalIdentifyingData')}
        </Typography>
        <Typography component="li" variant="body1">
          {t('privacyComponent.firstHx.retainInfoOnSecureServers')}
        </Typography>
        <Typography component="li" variant="body1">
          {t('privacyComponent.firstHx.notShareAnyInfo')}
        </Typography>
        <Typography component="li" variant="body1">
          {t('privacyComponent.firstHx.applySecuritySafeguards')}
        </Typography>
        <Typography component="li" variant="body1">
          {t('privacyComponent.firstHx.adheresRegulations')}
        </Typography>
      </Box>
      <Typography variant="body1" paragraph>
        {t('privacyComponent.contactFirstHx')}
      </Typography>
    </Box>
  );
}

export default FhxPrivacyInformation;
