import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Box, TextField, InputLabel, Typography,
} from '@mui/material';
import IntakeServices from '../../../services/IntakeServices';
import Button from '../../../common/Button';

const services = new IntakeServices();

export default function FhxReportViewer({
  report, mkey, questionNumber, onCommentSubmitted, onCommentSubmissionFailed,
}) {
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmitComment = async () => {
    try {
      setLoading(true);
      await services.submitReportComment(
        mkey,
        questionNumber,
        report,
        comment,
      );
      onCommentSubmitted();
    } catch (error) {
      onCommentSubmissionFailed(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container wrap="nowrap">
      <Grid item xs={8}>
        <Typography variant="h2" sx={{ borderBottom: 1, pb: 1 }}>
          Clinical report
        </Typography>
        <Box sx={{
          height: 800, overflow: 'auto',
        }}
        >
          <pre>{report}</pre>
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{ mx: 3 }}
      >
        <InputLabel htmlFor="comment-report" sx={{ color: 'text.primary', borderBottom: 1, pb: 1 }}>
          Comment on the report
        </InputLabel>
        <TextField
          id="comment-report"
          rows={15}
          sx={{ mt: 3, mb: 1, width: '100%' }}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
          multiline
        />
        <Button text="Send Comment" loading={loading} onClick={onSubmitComment} sx={{ mt: 2 }} />
      </Grid>
    </Grid>
  );
}

FhxReportViewer.propTypes = {
  report: PropTypes.string,
  mkey: PropTypes.string.isRequired,
  questionNumber: PropTypes.number.isRequired,
  onCommentSubmitted: PropTypes.func.isRequired,
  onCommentSubmissionFailed: PropTypes.func.isRequired,
};

FhxReportViewer.defaultProps = {
  report: '',
};
