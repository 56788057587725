import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog, DialogContent, DialogTitle, Divider, IconButton, DialogContentText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function Modal(props) {
  const {
    isOpen, handleClose, title, subtitle, maxWidth, children,
  } = props;
  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={isOpen}
      onClose={handleClose}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(255, 255, 255, 0.9)', // Adjust as needed
          backdropFilter: 'blur(3px)', // Adjust as needed
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: '16px',
        },
      }}
      data-testid="modal"
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontWeight: '600',
        }}
      >
        {title}
        <IconButton
          data-testid="closeModalBtn"
          autoFocus
          aria-label="close"
          onClick={() => handleClose()}
          sx={{ color: 'black' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {subtitle ? (
          <DialogContentText>
            {subtitle}
          </DialogContentText>
        ) : null}
        {children}
      </DialogContent>
    </Dialog>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  maxWidth: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Modal.defaultProps = {
  subtitle: null,
  children: null,
  maxWidth: 'sm',
};
