import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Container, Card, CardMedia, IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRollbar } from '@rollbar/react';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import imageCompression from 'browser-image-compression';
import { useTranslation } from 'react-i18next';
import Button from '../common/Button';
import { FhxTitle, FhxNavButtons } from '../common/FhxContentComponents';
import IntakeServices from '../services/IntakeServices';
import Toast, { useToast } from '../common/useToast';

const services = new IntakeServices();

function FhxFileUpload({
  id, setParentStateCB, inputRequired, title, helperText, backAllowed,
}) {
  const uploadRef = useRef(null);
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isContinueLoading, setIsContinueLoading] = useState(false);
  const rollbar = useRollbar();
  const MAX_FILE_LIMIT = 3;

  const {
    toastState,
    toastSetErrorNotification,
    toastOnClose,
  } = useToast();

  const compressFileToSizeLimit = async (file) => {
    if (file.size <= 200 * 1024) {
      return file; // Skip compression for files smaller than or equal to 200 KB
    }

    const options = { maxSizeMB: 0.2, useWebWorker: true };
    try {
      return await imageCompression(file, options);
    } catch (error) {
      rollbar.error('Error compressing file:', error);
      return file;
    }
  };

  /**
 * Compresses a list of files sequentially to avoid browser cache overload.
 *
 * This function takes an array of files and compresses each file one by one.
 *  The `reduce` method is used to process each item in the array (file) one after another.
 */
  const compressFilesSequentially = async (
    files,
  ) => files.reduce(async (accumulatedFilesPromise, file) => {
    const accumulatedCompressedFiles = await accumulatedFilesPromise;

    // Compress the current file and add it to the array of compressed files.
    const currentCompressedFile = await compressFileToSizeLimit(file);
    accumulatedCompressedFiles.push(currentCompressedFile);

    // Return the updated array of compressed files for the next iteration.
    return accumulatedCompressedFiles;
  }, Promise.resolve([]));

  const canAddMoreFiles = (newlySelectedFiles) => {
    const currentFileCount = selectedFiles.length;
    return currentFileCount + newlySelectedFiles.length <= MAX_FILE_LIMIT;
  };

  const updateSelectedFilesAndPreviews = (filesToUpdate) => {
    const updatedPreviewUrls = filesToUpdate.map((file) => {
      try {
        return URL.createObjectURL(file);
      } catch (error) {
        rollbar.error('Error creating object URL for file:', error);
        return null;
      }
    }).filter((url) => url !== null);

    setSelectedFiles((previousFiles) => [...previousFiles, ...filesToUpdate]);
    setPreviewUrls((previousUrls) => [...previousUrls, ...updatedPreviewUrls]);
  };

  const handleBackClick = () => {
    services
      .getPreviousIntake(global.mkey, id)
      .then((response) => {
        setParentStateCB(response.result ? response.result.data.content : null);
      })
      .catch((error) => {
        setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
      });
  };

  const handleContinueClick = () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });
    setIsContinueLoading(true);
    services.postIntake(global.mkey, id, formData)
      .then((response) => {
        setParentStateCB(response.result ? response.result.data.content : null);
        setSelectedFiles([]);
        setPreviewUrls([]);
      })
      .catch((response) => {
        if (response.response?.status === 409) {
          setParentStateCB('deactivated');
          return;
        }
        const errorMessage = response?.response?.data?.error;
        if (errorMessage) {
          toastSetErrorNotification(`Error uploading images. ${errorMessage}`);
        } else {
          toastSetErrorNotification('Error uploading images. Please try again.');
        }
      }).finally(() => {
        setIsContinueLoading(false);
      });
  };

  const handleFileChange = async (event) => {
    const filesUploadedByUser = Array.from(event.target.files);

    if (!canAddMoreFiles(filesUploadedByUser)) {
      toastSetErrorNotification(t('max_file_limit'));
      return;
    }
    setIsLoading(true);

    try {
      const compressedFiles = await compressFilesSequentially(filesUploadedByUser);
      updateSelectedFilesAndPreviews(compressedFiles);
    } catch (error) {
      rollbar.error('Error while processing files:', error);
      toastSetErrorNotification(t('error_compressing_files'));

      // Wait for 1 second before automatically proceeding to the next question if there is an error
      setTimeout(() => {
        handleContinueClick();
      }, 1000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveImage = (index) => {
    const newSelectedFiles = selectedFiles.filter((_, i) => i !== index);
    const newPreviewUrls = previewUrls.filter((_, i) => i !== index);
    setSelectedFiles(newSelectedFiles);
    setPreviewUrls(newPreviewUrls);
  };

  return (
    <>
      <Container maxWidth="md" sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <FhxTitle title={title} helperText={helperText} />
          <input
            ref={uploadRef}
            type="file"
            accept="image/*"
            multiple
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <Button
            component="span"
            variant="outlined"
            color="primary"
            startIcon={<FileUploadOutlinedIcon />}
            loading={isLoading}
            loadingPosition="start"
            text="Add Images"
            onClick={() => {
              uploadRef.current.click();
            }}
          />
          {previewUrls.length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {previewUrls.map((url, index) => (
                <Card
                  key={url}
                  sx={{
                    position: 'relative', width: 200, height: 200, margin: { xs: 'auto', md: 0 },
                  }}
                >
                  <CardMedia
                    data-testid="imagePreview"
                    tabIndex="0"
                    component="img"
                    image={url}
                    alt={`Image Preview ${index + 1}`}
                    sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                  <IconButton
                    aria-label="Delete"
                    size="small"
                    sx={{
                      position: 'absolute', top: 0, right: 0,
                    }}
                    onClick={() => handleRemoveImage(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Card>
              ))}
            </Box>
          )}
        </Box>
      </Container>
      <FhxNavButtons
        backAllowed={backAllowed}
        continueAllowed={!inputRequired}
        continueButtonCallback={handleContinueClick}
        backButtonCallback={handleBackClick}
        isLoading={isContinueLoading}
      />
      <Toast
        open={toastState.open}
        severity={toastState.severity}
        text={toastState.text}
        onClose={toastOnClose}
        autoHideDuration={6000}
      />
    </>
  );
}

FhxFileUpload.propTypes = {
  id: PropTypes.number.isRequired,
  setParentStateCB: PropTypes.func.isRequired,
  inputRequired: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  backAllowed: PropTypes.bool.isRequired,
};

FhxFileUpload.defaultProps = {
  helperText: '',
};

export default FhxFileUpload;
