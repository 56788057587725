/* eslint-disable react/prop-types */
import React from 'react';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  FormHelperText,
} from '@mui/material';

function Select(props) {
  const {
    // eslint-disable-next-line react/prop-types
    name, sx, label, children, value,
    error, onChange, input, renderValue, fullWidth = true, ...others
  } = props;

  return (
    <FormControl fullWidth={fullWidth} sx={sx} error={!!error}>
      <InputLabel id="select-label">{label}</InputLabel>
      <MuiSelect
        labelId="select-label"
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        input={input}
        renderValue={renderValue}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...others}
        fullWidth={fullWidth}
        data-testid={`select-${name}`}
      >
        {children}
      </MuiSelect>
      {
        error && <FormHelperText>{error}</FormHelperText>
      }
    </FormControl>
  );
}

export default Select;
