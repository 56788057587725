import React from 'react';
import PropTypes from 'prop-types';

function getResponsiveWidth(imgSrc) {
  if (typeof window !== 'undefined') {
    // parameterize the width parameters in the future. Perhaps we could send the parameters
    // from the back end along with the img parameter. Or perhaps it's better to store these
    // configurations as static elements in the public directory along with image
    if (imgSrc.includes('HMG')) {
      return window.innerWidth <= 768 ? '80%' : '30%'; // HMG requested a larger logo
    }
    return window.innerWidth <= 768 ? '40%' : '15%';
  }
  return '15%'; // Default to 15% if window is not defined
}

function ResponsiveLogo({ imgSrc }) {
  const imgStyle = {
    display: 'block',
    margin: 'auto',
    width: getResponsiveWidth(imgSrc),
    maxWidth: imgSrc === '/FirstHx_logo.png' ? '100px' : '',
  };
  return imgSrc ? <img src={imgSrc} alt="logo" style={imgStyle} /> : null;
}

ResponsiveLogo.propTypes = {
  imgSrc: PropTypes.string,
};

ResponsiveLogo.defaultProps = {
  imgSrc: '',
};

export default ResponsiveLogo;
