import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@mui/material';
import IntakeServices from '../../../services/IntakeServices';
import Button from '../../../common/Button';

const services = new IntakeServices();

export default function FhxRestartButton(props) {
  const { mkey, setContent } = props;

  const onClick = () => services.restartIntake(mkey).then((response) => {
    setContent(response.result ? response.result.data.content : null);
  });

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
    >
      <Grid
        item
        alignItems="center"
        display="flex"
        direction="column"
      >
        <Button
          variant="outlined"
          sx={{ my: 2 }}
          text="Restart Intake"
          onClick={onClick}
        />
        <Typography variant="caption">(All comments are saved)</Typography>
      </Grid>
    </Grid>
  );
}

FhxRestartButton.propTypes = {
  mkey: PropTypes.string.isRequired,
  setContent: PropTypes.func.isRequired,
};
