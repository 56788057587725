import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Box,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FhxTitle, FhxNavButtons } from '../common/FhxContentComponents';
import IntakeServices from '../services/IntakeServices';
import FhxBodyMapLazyLoad from './FhxBodyMapLazyLoad';
import FhxBodyMapControlToggle from './FhxBodyMapControlToggle';
import FhxRadioButtonGroup from './FhxRadioButtonGroup';

const services = new IntakeServices();

function FhxSelectOne({
  title,
  backAllowed,
  inputRequired,
  options,
  setParentStateCB,
  id,
  helperText,
  isTestDrive,
  type,
  bodyMapToDisplay,
}) {
  const [contBtnState, setContBtnState] = useState(!inputRequired);
  const maxColLength = 6;
  const isMobile = isTestDrive || useMediaQuery('(max-width:700px)');

  const [selectedID, setSelectedID] = useState(null);
  const [isImageMode, setIsImageMode] = useState(true);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [loading, setLoading] = useState(false);
  const handleContinueClick = () => {
    setLoading(true);

    const selected = options.find((option) => option.id === selectedID);
    services.postIntake(global.mkey, id, { options: [selected] }).then((response) => {
      setParentStateCB(response.result ? response.result.data.content : null);
      if (window.top !== window.self) {
        if (response.result.data.intakeStatus !== 'consentDeclined') {
          window.parent.postMessage('Activity', '*');
        }
      }
    }).catch((error) => {
      setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleBackClick = () => {
    services.getPreviousIntake(global.mkey, id).then((response) => {
      setParentStateCB(response.result ? response.result.data.content : null);
    }).catch((error) => {
      setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
    });
  };

  useEffect(() => {
    setSelectedID(null);
    if (type !== 'selectOneBodyPart') {
      setFilteredOptions(options);
    }
    setContBtnState(!inputRequired);
    window.scrollTo(0, 0);
  }, [title, options, inputRequired]);

  const handleChoiceChange = (event, newChoice) => {
    const newSelectedId = Number(newChoice);
    setSelectedID(newSelectedId);
    setContBtnState(!inputRequired || newSelectedId !== null);
  };

  const handleViewModeToggle = () => {
    setIsImageMode(!isImageMode);
  };

  // Render options for selecting a body part
  const renderBodyPartOptions = () => (
    <Box component="span" m={1} display="flex" justifyContent="center">
      {isImageMode ? (
        <FhxBodyMapLazyLoad
          options={options}
          bodyMapToDisplay={bodyMapToDisplay}
          handleChoiceChange={handleChoiceChange}
          setFilteredOptions={setFilteredOptions}
          selectedIDs={[selectedID]}
        />
      ) : (
        <FhxRadioButtonGroup
          options={filteredOptions}
          selectedID={selectedID}
          handleChoiceChange={handleChoiceChange}
        />
      )}
    </Box>
  );

  // Render options for other types (non-body part selections)
  const renderOtherOptions = () => (
    <Box component="span" my={1} display="flex" justifyContent="center">
      {filteredOptions.length >= maxColLength && !isMobile ? (
        <Container
          disableGutters
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px !important',
          }}
        >
          <Box sx={{ width: '45%' }}>
            <FhxRadioButtonGroup
              options={filteredOptions.slice(0, Math.floor(filteredOptions.length / 2))}
              selectedID={selectedID}
              handleChoiceChange={handleChoiceChange}
            />
          </Box>
          <Box sx={{ width: '45%' }}>
            <FhxRadioButtonGroup
              options={filteredOptions.slice(Math.floor(filteredOptions.length / 2))}
              selectedID={selectedID}
              handleChoiceChange={handleChoiceChange}
            />
          </Box>
        </Container>
      ) : (
        <FhxRadioButtonGroup
          options={filteredOptions}
          selectedID={selectedID}
          handleChoiceChange={handleChoiceChange}
        />
      )}
    </Box>
  );

  // Main render function that decides which option type to render
  const renderOptions = () => {
    if (type === 'selectOneBodyPart') {
      return renderBodyPartOptions();
    } if (Array.isArray(options)) {
      return renderOtherOptions();
    }
    return null;
  };

  return (
    <>
      <Container maxWidth="md" sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <FhxTitle title={title} helperText={helperText} />
        {type === 'selectOneBodyPart' && (
        <FhxBodyMapControlToggle
          isImageMode={isImageMode}
          handleViewModeToggle={handleViewModeToggle}
        />
        )}
        {renderOptions()}
      </Container>
      <FhxNavButtons
        backAllowed={backAllowed}
        continueAllowed={contBtnState}
        continueButtonCallback={handleContinueClick}
        backButtonCallback={handleBackClick}
        isLoading={loading}
      />
    </>
  );
}

FhxSelectOne.propTypes = {
  title: PropTypes.string.isRequired,
  backAllowed: PropTypes.bool.isRequired,
  inputRequired: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      displayText: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  setParentStateCB: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  helperText: PropTypes.string,
  isTestDrive: PropTypes.bool,
  type: PropTypes.string.isRequired,
  bodyMapToDisplay: PropTypes.oneOf(['femaleChild', 'femaleAdult', 'maleChild', 'maleAdult', undefined]),
};

FhxSelectOne.defaultProps = {
  helperText: '',
  isTestDrive: false,
  bodyMapToDisplay: undefined,
};

export default FhxSelectOne;
