/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Box, Stack, Typography, Link,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import DOMPurify from 'dompurify';
import { useTranslation, Trans } from 'react-i18next';
import IntakeServices from '../services/IntakeServices';
import Modal from '../common/Modal';
import FhxPrivacyInformation from '../common/FhxPrivacyInformation';
import ResponsiveLogo from '../common/ResponsiveLogo';
import { Controls } from '../common/Controls';

const services = new IntakeServices();

function ConsentButtons(props) {
  const { handleAgree, handleDisagree, disagreeButtonDisabled } = props;
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:450px)');
  const theme = useTheme();

  const getButtonStyle = (styleType) => ({
    maxWidth: styleType === 'fullWidth' ? 'none' : '350px',
    width: styleType === 'fullWidth' ? '100%' : 'auto',
    fontWeight: '700',
    margin: '0 !important',
  });

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent={disagreeButtonDisabled ? 'center' : 'space-between'}
      sx={{
        pt: isMobile ? 3 : 6,
        margin: '0 auto',
        width: isMobile ? '100%' : '80%',
      }}
    >
      {disagreeButtonDisabled !== true && (
        <Controls.Button
          display="inline-block"
          variant="outlined"
          id="disagree"
          data-testid="dButton"
          color="primary"
          loadingPosition="start"
          startIcon={<CloseRoundedIcon />}
          onClick={handleDisagree}
          text={t('disagree')}
          sx={getButtonStyle(theme.contentButtonStyle)}
        />
      )}
      <Controls.Button
        display="inline-block"
        variant="contained"
        id="agree"
        data-testid="aButton"
        color="primary"
        onClick={handleAgree}
        startIcon={<CheckRoundedIcon />}
        text={t('agree')}
        sx={getButtonStyle(theme.contentButtonStyle)}
      />
    </Stack>
  );
}

ConsentButtons.propTypes = {
  handleAgree: PropTypes.func.isRequired,
  handleDisagree: PropTypes.func.isRequired,
  disagreeButtonDisabled: PropTypes.bool.isRequired,
};

function FhxConsent(props) {
  const {
    html,
    img,
    setParentStateCB,
    id,
    consentPageLogoOn,
    privacyPolicyEnabled,
    privacyPolicyTextEnglish,
    privacyPolicyTextArabic,
    privacyPolicyTextFrenchCanadian,
    disagreeButtonDisabled,
  } = props;
  const contentRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(null);

  const handleAgree = () => {
    if (loading) return;
    setLoading(true);
    services
      .postIntake(global.mkey, id, { options: [{ id: 0, displayText: 'I agree' }] })
      .then((response) => {
        setParentStateCB(response.result ? response.result.data.content : null);
        if (window.top !== window.self) {
          window.parent.postMessage('Activity', '*');
        }
      }).catch((error) => {
        setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
      })
      .finally(() => setLoading(null));
  };

  const handleDisagree = () => {
    if (loading) return;
    setLoading(true);
    services
      .postIntake(global.mkey, id, { options: [{ id: 1, displayText: 'I disagree' }] })
      .then((response) => {
        setParentStateCB(response.result ? response.result.data.content : null);
      }).catch((error) => {
        setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
      })
      .finally(() => setLoading(null));
  };

  const textAlignment = i18n.language === 'ar' ? 'right' : 'left';
  let privacyPolicyText = '';
  if (i18n.language === 'ar') {
    privacyPolicyText = privacyPolicyTextArabic;
  } else if (i18n.language === 'fr_CA') {
    privacyPolicyText = privacyPolicyTextFrenchCanadian;
  } else {
    privacyPolicyText = privacyPolicyTextEnglish;
  }

  let privacyPolicyLinkText = '';
  if (i18n.language === 'ar') {
    privacyPolicyLinkText = 'هنا';
  } else if (i18n.language === 'fr_CA') {
    privacyPolicyLinkText = 'ICI';
  } else {
    privacyPolicyLinkText = 'HERE';
  }

  const renderHtmlContent = () => {
    const linkHtml = `<button id="privacyPolicyLink">${privacyPolicyLinkText}</button>`;
    const modifiedHtml = html.replace('{{PRIVACY_POLICY}}', linkHtml);

    return DOMPurify.sanitize(modifiedHtml);
  };

  useEffect(() => {
    const privacyPolicyLink = document.getElementById('privacyPolicyLink');
    if (privacyPolicyLink) {
      privacyPolicyLink.onclick = () => setModalOpen(true);
    }
  }, [html, privacyPolicyEnabled]);

  // Use useEffect to set focus on the h1 element when the component mounts or html prop changes.
  // This improves accessibility by providing a clear starting point for screen readers,
  // indicating that new content has been loaded.
  useEffect(() => {
    if (contentRef.current) {
      const h1 = contentRef.current.querySelector('h1');
      if (h1) {
        h1.setAttribute('tabindex', '-1');
        h1.style.outline = 'none';
        h1.focus();
      }
    }
  }, [html]);

  return (
    <div>
      <div className="logoContainer" style={{ marginTop: '20px' }}>
        {img && consentPageLogoOn ? <ResponsiveLogo imgSrc={img} data-testid="logoImage" /> : null}
      </div>
      <Container data-testid="fhx-consent" className="bodyContainer" maxWidth="md" sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <Box m={1} data-testid="bodyText" sx={{ display: 'block', justifyContent: 'center', alignItems: 'center' }}>
          {/* eslint-disable-next-line */}
          <span ref={contentRef} dangerouslySetInnerHTML={{ __html: renderHtmlContent() }} />
        </Box>
        {privacyPolicyEnabled && !privacyPolicyText && (
          <Box m={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography mr="1" sx={{ fontSize: 'smaller', textAlign: 'center' }}>
              <Trans
                i18nKey="privacyComponent.softwareInformation"
                components={[
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => setModalOpen(true)}
                  />,
                ]}
              />
            </Typography>
          </Box>
        )}
        <ConsentButtons
          disagreeButtonDisabled={disagreeButtonDisabled}
          handleAgree={handleAgree}
          handleDisagree={handleDisagree}
          loading={loading}
        />
      </Container>
      <Modal isOpen={modalOpen} handleClose={() => setModalOpen(false)} title={t('privacyComponent.privacyDocumentTitle')}>
        {privacyPolicyText ? (
          <Box
            m={1}
            data-testid="bodyText"
            sx={{ display: 'block', justifyContent: textAlignment, alignItems: textAlignment }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(privacyPolicyText || null) }}
          />
        ) : <FhxPrivacyInformation />}
      </Modal>
    </div>
  );
}

FhxConsent.propTypes = {
  html: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  setParentStateCB: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  consentPageLogoOn: PropTypes.bool.isRequired,
  privacyPolicyEnabled: PropTypes.bool.isRequired,
  privacyPolicyTextEnglish: PropTypes.string,
  privacyPolicyTextArabic: PropTypes.string,
  privacyPolicyTextFrenchCanadian: PropTypes.string,
  disagreeButtonDisabled: PropTypes.bool.isRequired,
};

FhxConsent.defaultProps = {
  privacyPolicyTextEnglish: '',
  privacyPolicyTextArabic: '',
  privacyPolicyTextFrenchCanadian: '',
};

export default FhxConsent;
