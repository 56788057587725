import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRollbar } from '@rollbar/react';
import { Box, Button, Container } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import FhxLocalization from '../content/FhxLocalization';
import FhxPtInitiatedIntakeForm from '../content/FhxPtInitiatedIntakeForm';
import IntakeServices from '../services/IntakeServices';
import Toast, { useToast } from '../common/useToast';
import FhxError from '../content/FhxError';

const services = new IntakeServices();

function PtInitiatedIntakePage() {
  const rollbar = useRollbar();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const siteId = searchParams.get('siteId');
  if (!siteId) return <FhxError />;
  const skipCaptcha = searchParams.get('sc') === 'true' || false;
  const [recaptchaLanguage, setRecaptchaLanguage] = useState('en');
  const [loading, setLoading] = useState(false);
  // default to english if no language param is provided
  const languages = searchParams.get('languages')?.split(',') || ['en'];

  const availableLanguages = {
    en: 'English',
    fr_CA: 'Français',
    ar: 'العربية',
  };

  // ensure only available languages are used
  const filteredLanguages = languages
    .filter((lang) => Object.keys(availableLanguages).includes(lang));

  const { toastState, toastSetErrorNotification, toastOnClose } = useToast();

  const handlePiiSubmit = ({ pii, captchaToken }) => {
    const piiWithPtSelectedLang = {
      ...pii,
      patientLanguage: i18n.language,
    };
    setLoading(true);
    services.createPatientInitiatedIntake(siteId, piiWithPtSelectedLang, captchaToken).then(
      (response) => {
        global.mkey = response?.result?.data.mkey;
        if (global.mkey) {
          navigate(`?mkey=${global.mkey}`);
        }
      },
    ).catch((error) => {
      rollbar.error('Failed to submit PII data', error);
      toastSetErrorNotification(t('errMsgToContactSupport'));
    }).finally(() => {
      setLoading(false);
    });
  };

  const getCaptchaLangCode = (languageCode) => {
    const langMap = {
      en: 'en',
      fr_CA: 'fr-CA',
      ar: 'ar',
    };
    return langMap[languageCode] || languageCode;
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setRecaptchaLanguage(getCaptchaLangCode(lang));
  };

  useEffect(() => {
    changeLanguage(filteredLanguages[0]);
  }, []);

  return (
    <FhxLocalization theme={createTheme({})} localization={i18n}>
      <Container maxWidth="md" sx={{ paddingTop: 3, paddingBottom: 2 }}>
        <Box display="flex" justifyContent="center" mb={2}>
          {filteredLanguages.map((lang) => (
            <Button
              key={lang}
              onClick={() => changeLanguage(lang)}
              variant={i18n.language === lang ? 'outlined' : 'primary'}
            >
              {availableLanguages[lang]}
            </Button>
          ))}
        </Box>
        <FhxPtInitiatedIntakeForm
          onSubmit={handlePiiSubmit}
          skipCaptcha={skipCaptcha}
          recaptchaLanguage={recaptchaLanguage}
          toastSetErrorNotification={toastSetErrorNotification}
          isLoading={loading}
        />
        <Toast
          open={toastState.open}
          severity={toastState.severity}
          text={toastState.text}
          onClose={toastOnClose}
          autoHideDuration={6000}
        />
      </Container>
    </FhxLocalization>
  );
}

export default PtInitiatedIntakePage;
