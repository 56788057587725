import React from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider, DateField as MuiDateField } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

function DateField(props) {
  const {
    value, setValue, disableFuture, setIsValid, label, ...other
  } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDateField
        label={label}
        value={value}
        disableFuture={disableFuture}
        onChange={setValue}
        onError={(error) => setIsValid(!error)}
        slotProps={{
          textField: {
            error: false,
            inputProps: {
              'aria-label': 'Date',
            },
          },
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
      />
    </LocalizationProvider>
  );
}

DateField.propTypes = {
  value: PropTypes.instanceOf(Date),
  setValue: PropTypes.func.isRequired,
  disableFuture: PropTypes.bool,
  setIsValid: PropTypes.func.isRequired,
  label: PropTypes.string,
};

DateField.defaultProps = {
  value: null,
  disableFuture: false,
  label: '',
};

export default DateField;
