import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRollbar } from '@rollbar/react';
import { Grid } from '@mui/material';
import ButtonWithModal from '../../../common/ButtonWithModal';
import IntakeServices from '../../../services/IntakeServices';
import FhxReportViewer from './FhxReportViewer';

const services = new IntakeServices();

export default function FhxViewReportButton(props) {
  const { mkey, questionNumber, showSnackbarMessage } = props;
  const [report, setReport] = useState(null);
  const [reportModalIsOpen, setReportModalIsOpen] = useState(false);

  const rollbar = useRollbar();

  const loadReport = async () => {
    const response = await services.getReportForTestDrive(mkey);
    setReport(response.result.data.report);
  };

  const onCommentSubmitted = () => {
    setReportModalIsOpen(false);
    showSnackbarMessage('Comment submitted');
  };

  const onCommentSubmissionFailed = (error) => {
    rollbar.error(`Test Drive: Report comment submission failed: ${error}`);
    showSnackbarMessage('Comment submission failed. Please notify the FirstHx team.');
  };

  return (
    <Grid
      item
      alignItems="center"
      display="flex"
    >
      <ButtonWithModal
        variant="outlined"
        data-testid="viewReportButton"
        buttonText="View Clinical Report"
        modalTitle="Report"
        modalMaxWidth="xl"
        modalIsOpen={reportModalIsOpen}
        onClick={async () => {
          await loadReport();
          setReportModalIsOpen(true);
        }}
        onClose={() => setReportModalIsOpen(false)}
      >
        <FhxReportViewer
          report={report}
          mkey={mkey}
          questionNumber={questionNumber}
          onCommentSubmitted={onCommentSubmitted}
          onCommentSubmissionFailed={onCommentSubmissionFailed}
        />
      </ButtonWithModal>
    </Grid>
  );
}

FhxViewReportButton.propTypes = {
  mkey: PropTypes.string.isRequired,
  questionNumber: PropTypes.number.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
};
