import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Box,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import FhxToggleButtonGroup from './FhxToggleButtonGroup';
import { FhxTitle } from '../common/FhxContentComponents';
import IntakeServices from '../services/IntakeServices';

const services = new IntakeServices();

function FhxLanguageSelectOne({
  title, inputRequired, options, setParentStateCB, id, setCustomLocalization,
}) {
  const [selectedID, setSelectedID] = useState(null);
  const maxColLength = 6;
  const isMobile = useMediaQuery('(max-width:700px)');

  const updateSelectedLocalization = async (selectedChoice) => {
    const selected = options.find((option) => option.id === selectedChoice);
    const selectedLocalization = selected.displayText;
    const response = await services.updateLocalization(global.mkey, id, {
      options: [selected], selectedLocalization,
    });
    return { response, selectedLocalization };
  };

  const handleChoiceChange = async (event, indexOfNewChoice) => {
    setSelectedID(indexOfNewChoice);
    if (indexOfNewChoice !== null && indexOfNewChoice !== undefined) {
      const { response, selectedLocalization } = await updateSelectedLocalization(indexOfNewChoice);

      // Update parent state
      setCustomLocalization(selectedLocalization);
      setParentStateCB(response.result ? response.result.data.content : null);
      if (window.top !== window.self) {
        window.parent.postMessage('Activity', '*');
      }
    }
  };

  useEffect(() => {
    setSelectedID(null);
    window.scrollTo(0, 0);
  }, [title, options, inputRequired]);

  // show the translated version to user
  const modifiedOptions = options.map((option) => {
    if (option && option.displayText) {
      switch (option.displayText.toLowerCase()) {
        case 'arabic':
          return {
            ...option,
            displayText: 'عربي',
          };
        case 'french-canadian':
          return {
            ...option,
            displayText: 'Français (Canada)',
          };
        default:
          return option;
      }
    } else {
      return option;
    }
  });

  return Array.isArray(modifiedOptions) ? (
    <Container maxWidth="md" sx={{ paddingTop: 2, paddingBottom: 2 }}>
      <FhxTitle title="Select Language:" isHidden />
      <Box component="span" m={1} display="flex" justifyContent="center">
        {modifiedOptions.length >= maxColLength && !isMobile ? (
          <Container
            disableGutters
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                width: '45%',
              }}
            >
              <FhxToggleButtonGroup
                options={modifiedOptions.slice(0, Math.floor(options.length / 2))}
                selectedID={selectedID}
                handleChoiceChange={handleChoiceChange}
              />
            </Box>
            <Box
              sx={{
                width: '45%',
              }}
            >
              <FhxToggleButtonGroup
                options={modifiedOptions.slice(Math.floor(options.length / 2))}
                selectedID={selectedID}
                handleChoiceChange={handleChoiceChange}
              />
            </Box>
          </Container>
        )
          : (
            <FhxToggleButtonGroup
              options={modifiedOptions}
              selectedID={selectedID}
              handleChoiceChange={handleChoiceChange}
            />
          )}
      </Box>
    </Container>
  ) : null;
}

FhxLanguageSelectOne.propTypes = {
  title: PropTypes.string.isRequired,
  inputRequired: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      displayText: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  setParentStateCB: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  setCustomLocalization: PropTypes.func.isRequired,
};

export default FhxLanguageSelectOne;
