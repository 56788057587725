import React from 'react';
import './honeycomb';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as LoggingProvider, ErrorBoundary } from '@rollbar/react';
import App from './App';
import './i18n';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_NODE_ENV,
  enabled: ['development', 'staging', 'production'].includes(process.env.REACT_APP_NODE_ENV),
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LoggingProvider config={rollbarConfig}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </LoggingProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
