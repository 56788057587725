import axios from 'axios';

const formatResponse = (response) => {
  if (response.status === 200) return { result: response };
  return { result: undefined, error: response.data };
};

export default class IntegrationService {
  constructor() {
    this.client = null;
    this.api_url = process.env.REACT_APP_INTEGRATION_ENDPOINT;
  }

  init() {
    const headers = {
      Accept: 'application/json',
    };

    this.client = axios.create({ baseURL: `${this.api_url}/v1/private/`, timeout: 30000, headers });
    return this.client;
  }

  async getSpeechToken(mkey) {
    const result = await this.init().post('/getSpeechToken', { mkey });
    return formatResponse(result);
  }
}
