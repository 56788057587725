import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Box, TextField, ToggleButtonGroup, ToggleButton,
} from '@mui/material';
import { isEmpty, isNull } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FhxTitle, FhxNavButtons } from '../common/FhxContentComponents';
import IntakeServices from '../services/IntakeServices';

const services = new IntakeServices();

function FhxNumberFormTextBox({
  title,
  backAllowed,
  setParentStateCB,
  id,
  inputRequired,
  units,
  helperText,
  validator,
}) {
  const [value, setValue] = useState('');
  const [contBtnState, setContBtnState] = useState(!inputRequired);
  const [indexOfSelectedUnit, setIndexOfSelectedUnit] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleContinueClick = () => {
    const numeric = {
      value: parseFloat(value),
    };
    if (!isNull(indexOfSelectedUnit)) {
      numeric.indexOfUnitChosen = indexOfSelectedUnit;
    }
    const invalidMessage = validator(numeric);
    if (invalidMessage) {
      setErrorMessage(invalidMessage);
      return;
    }
    setLoading(true);
    services
      .postIntake(global.mkey, id, {
        id,
        numeric,
      })
      .then((response) => {
        setValue('');
        setIndexOfSelectedUnit(null);
        setErrorMessage('');
        setParentStateCB(response.result ? response.result.data.content : null);
        if (window.top !== window.self) {
          window.parent.postMessage('Activity', '*');
        }
      }).catch((error) => {
        setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
      }).finally(() => {
        setLoading(false);
      });
  };

  const handleBackClick = () => {
    services
      .getPreviousIntake(global.mkey, id)
      .then((response) => {
        setParentStateCB(response.result ? response.result.data.content : null);
      })
      .catch((error) => {
        setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
      });
  };

  const handleUnitChange = (event, newUnit) => {
    setIndexOfSelectedUnit(newUnit);
    if (inputRequired) {
      setContBtnState(!isNull(newUnit) && !isEmpty(value));
    }
  };

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (inputRequired) {
      setContBtnState(!isEmpty(newValue) && (isEmpty(units) || !isNull(indexOfSelectedUnit)));
    }
  };

  useEffect(() => {
    if (units?.length === 1) {
      handleUnitChange(null, 0);
    }
    setContBtnState(!inputRequired);
  }, [units, inputRequired]);

  return (
    <>
      <Container maxWidth="md" sx={{ py: 2 }}>
        <FhxTitle title={title} helperText={helperText} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            my: 1,
          }}
        >
          <TextField
            margin="normal"
            id="outlined-number"
            label={t('numericQuestion.number')}
            value={value}
            onChange={handleValueChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ type: 'number', inputMode: 'decimal', 'data-testid': 'numberInput' }}
            error={!!errorMessage}
            helperText={errorMessage}
          />
          <ToggleButtonGroup
            orientation="vertical"
            color="primary"
            value={indexOfSelectedUnit}
            exclusive
            onChange={handleUnitChange}
            sx={{ mx: 2 }}
          >
            {units?.map((unit, index) => {
              if (isEmpty(unit)) return null;
              return (
                <ToggleButton
                  data-testid={`unitButton-${unit}`}
                  key={unit}
                  style={{ display: 'flex', justifyContent: 'center' }}
                  value={index}
                >
                  {unit}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Box>
      </Container>
      <FhxNavButtons
        backAllowed={backAllowed}
        continueAllowed={contBtnState}
        continueButtonCallback={handleContinueClick}
        backButtonCallback={handleBackClick}
        isLoading={loading}
      />
    </>
  );
}

FhxNumberFormTextBox.propTypes = {
  title: PropTypes.string.isRequired,
  backAllowed: PropTypes.bool.isRequired,
  setParentStateCB: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  inputRequired: PropTypes.bool.isRequired,
  units: PropTypes.arrayOf(PropTypes.string).isRequired,
  helperText: PropTypes.string,
  validator: PropTypes.func,

};

FhxNumberFormTextBox.defaultProps = {
  helperText: '',
  validator: () => {},

};

export default FhxNumberFormTextBox;
