import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { round } from 'lodash';
import {
  Slider, Typography, Box, IconButton, FormHelperText,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import {
  convertCmToFtInches,
  convertKgToLbs,
  convertDaysToWeeksDays,
} from './UnitConversions';
import '../App.css';

/**
 *
 * @param {string} type The type of measurement (e.g., 'height', 'weight', 'gestationalAge').
 * @param {number} value The numerical value to be converted and formatted.
 * @returns {string} A formatted string representing the converted measurement.
 *
 * The function supports various types of measurements:
 * - 'height': Converts from centimeters to feet and inches.
 * - 'weight': Converts from kilograms to pounds.
 * - 'gestationalAge': Formats the number of days into weeks and days.
 * The default case returns the value without any conversion or formatting.
 */

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 5,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    border: '2px solid currentColor',
    backgroundColor: '#fff',
    boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 5,
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    boxShadow: 'inset 0px 0px 4px -2px #000',
    backgroundColor: theme.palette.background.main,
  },
}));

function convertAndFormatMeasurement(type, value) {
  const { t } = useTranslation();
  const marginStyle = {
    margin: '0 5px',
  };

  switch (type) {
    case 'height': {
      const height = convertCmToFtInches(value);
      return (
        <span className="centeredFlex">
          <strong style={marginStyle}>{height.cm}</strong>
          <Typography variant="body1" sx={{ mr: 0.5 }}>cm</Typography>
          &#8776;
          <strong style={marginStyle}>
            {height.feet}
          </strong>
          <Typography variant="body1" sx={{ mr: 0.5 }}>ft</Typography>
          <strong style={marginStyle}>
            {height.inches}
          </strong>
          <Typography variant="body1" sx={{ mr: 0.5 }}>in</Typography>
        </span>
      );
    }
    case 'weight': {
      const weight = convertKgToLbs(value);
      return (
        <span className="centeredFlex">
          <strong style={marginStyle}>{weight.kg}</strong>
          <Typography variant="body1" sx={{ mr: 0.5 }}>
            kg
          </Typography>
          &#8776;
          <strong style={marginStyle}>{weight.lbs}</strong>
          <Typography variant="body1">lbs</Typography>
        </span>
      );
    }
    case 'gestationalAge': {
      const daysInTranslated = t('days');
      const weeksInTranslated = t('weeks');
      const [weeks, remainingDays] = convertDaysToWeeksDays(value);
      return (
        <span className="centeredFlex" data-testid="ageSlider">
          <strong style={marginStyle}>{weeks}</strong>
          <Typography variant="body1">{weeksInTranslated}</Typography>
          <strong style={marginStyle}>{remainingDays}</strong>
          <Typography variant="body1">{daysInTranslated}</Typography>
        </span>
      );
    }
    default:
      return value;
  }
}

function AdjustableSlider({
  minValue,
  maxValue,
  identifier,
  sliderType,
  stepSize,
  onValueChange,
  sliderValue,
  errorMessage,
}) {
  const [value, setValue] = useState(sliderValue);
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:450px)');

  useEffect(() => {
    setValue(sliderValue);
  }, [sliderValue, sliderType]);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    onValueChange(newValue);
  };

  const incrementValue = () => {
    let newValue = value + stepSize;
    newValue = round(newValue, 1); // Use lodash round to round to the nearest tenth
    newValue = Math.min(newValue, maxValue);
    setValue(newValue);
    onValueChange(newValue);
  };

  const decrementValue = () => {
    let newValue = value - stepSize;
    newValue = round(newValue, 1); // Use lodash round to round to the nearest tenth
    newValue = Math.max(newValue, minValue);
    setValue(newValue);
    onValueChange(newValue);
  };

  return (
    <Box
      sx={{
        width: isMobile ? '310px' : '440px',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.border.main}`,
        borderRadius: '10px',
        margin: '40px 10px',
        padding: '30px 40px',
        backgroundColor: theme.palette.background.secondary,
      }}
    >
      <IconButton onClick={decrementValue} disabled={value <= minValue} data-testid="decrease" aria-label="Decrease" sx={{ mb: 1.5, color: grey[900] }}>
        <RemoveCircleOutlineIcon />
      </IconButton>
      <Box sx={{ mx: 2, flex: 1 }}>
        <Typography id={identifier} sx={{ mb: 1.5 }}>
          {convertAndFormatMeasurement(sliderType, value)}
        </Typography>
        <CustomSlider
          value={value}
          onChange={handleSliderChange}
          valueLabelDisplay="off"
          min={minValue}
          max={maxValue}
          step={stepSize}
          aria-labelledby={identifier}
        />
      </Box>
      <IconButton onClick={incrementValue} disabled={value >= maxValue} data-testid="increase" aria-label="Increase" sx={{ mb: 1.5, color: grey[900] }}>
        <AddCircleOutlineOutlinedIcon />
      </IconButton>
      {!!errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </Box>
  );
}

AdjustableSlider.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  identifier: PropTypes.number.isRequired,
  sliderType: PropTypes.oneOf(['height', 'weight', 'gestationalAge']).isRequired,
  stepSize: PropTypes.number,
  sliderValue: PropTypes.number.isRequired,
  errorMessage: PropTypes.string,
};

AdjustableSlider.defaultProps = {
  stepSize: 1,
  errorMessage: '',
};

export default AdjustableSlider;
