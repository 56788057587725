import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormControlLabel, Checkbox, FormGroup,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { FhxHelperTextButton, HelperTextModal } from '../common/FhxContentComponents';

export default function FhxCheckboxGroup({ options, selectedIDs, handleChoiceChange }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState(null);
  const theme = useTheme();

  const handleIconClick = (option) => (e) => {
    e.stopPropagation();
    setCurrentOption(option);
    setModalOpen(true);
  };

  const determineLabelStyles = (optionId) => {
    const styles = {
      backgroundColor: 'transparent',
      borderColor: theme.palette.border.main,
    };

    if (theme.checkBoxStyle === 'transparent') {
      // If 'transparent' style is set, always use a transparent background color
      // e.g. in mapleTheme
      styles.backgroundColor = 'transparent';
    } else if (selectedIDs.includes(optionId)) {
      styles.backgroundColor = theme.palette.primary.main;
      styles.borderColor = theme.palette.primary.main;
    }
    return styles;
  };

  const determineLabelTextColor = (optionId) => {
    if (theme.checkBoxStyle === 'transparent') {
      return selectedIDs.includes(optionId) ? 'black' : 'inherit';
    }
    return selectedIDs.includes(optionId) ? 'white' : 'inherit';
  };

  const renderCheckboxOptionLabel = (option) => (
    <div className="centeredFlex" style={{ fontSize: '0.875em' }}>
      <p
        style={{
          color: determineLabelTextColor(option.id),
          margin: 0,
        }}
      >
        {option.displayText}
      </p>
      {option?.helperText && (
        <FhxHelperTextButton
          title={option.displayText}
          onButtonClick={handleIconClick(option)}
        />
      )}
    </div>
  );

  return (
    <FormControl fullWidth>
      <FormGroup
        sx={{
          gap: '10px',
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.id}
            sx={{
              ...determineLabelStyles(option.id),
            }}
            data-testid={`choice${option.id}`}
            value={option.id}
            label={renderCheckboxOptionLabel(option)}
            control={(
              <Checkbox
                data-testid={`checkbox${option.id}`}
                disableRipple
                checked={selectedIDs.includes(option.id)}
                onChange={(e) => {
                  handleChoiceChange(e, option.id);
                }}
              />
            )}
          />
        ))}
      </FormGroup>
      {currentOption?.helperText && (
        <HelperTextModal
          title={currentOption.displayText}
          helperText={currentOption.helperText}
          modalOpen={modalOpen}
          onCloseRequest={() => setModalOpen(false)}
        />
      )}
    </FormControl>
  );
}

FhxCheckboxGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    displayText: PropTypes.string.isRequired,
    helperText: PropTypes.string,
  })).isRequired,
  selectedIDs: PropTypes.arrayOf(PropTypes.number),
  handleChoiceChange: PropTypes.func.isRequired,
};

FhxCheckboxGroup.defaultProps = {
  selectedIDs: null,
};
