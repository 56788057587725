import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import DOMPurify from 'dompurify';
import { FhxNavButtons } from '../common/FhxContentComponents';
import IntakeServices from '../services/IntakeServices';
import ResponsiveLogo from '../common/ResponsiveLogo';

const services = new IntakeServices();

function FhxText({
  html,
  img,
  inputRequired,
  backAllowed,
  setParentStateCB,
  id,
}) {
  const contentRef = useRef(null);
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const handleContinueClick = () => {
    setLoading(true);
    services
      .postIntake(global.mkey, id, [])
      .then((response) => {
        setParentStateCB(
          response.result ? response.result.data.content : null,
        );
        if (window.top !== window.self) {
          window.parent.postMessage('Activity', '*');
        }
      }).catch((error) => {
        setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
      }).finally(() => {
        setLoading(false);
      });
  };
  const handleBackClick = () => {
    services
      .getPreviousIntake(global.mkey, id)
      .then((response) => {
        setParentStateCB(
          response.result ? response.result.data.content : null,
        );
      })
      .catch((error) => {
        setParentStateCB(error?.response?.status === 410 ? 'deactivated' : null);
      });
  };

  const transformFirstHtmlElementToH1 = (htmlContent) => {
    if (!htmlContent) return null;
    const updatedHtml = htmlContent.replace(
      /<div>(.*?)<\/div>/, // matches the first <div> element
      `<h1 style="font-size: ${theme.typography.body1.fontSize};">$1</h1>`,
    );
    return DOMPurify.sanitize(updatedHtml);
  };

  // Use useEffect to set focus on the h1 element when the component mounts or html prop changes.
  // This improves accessibility by providing a clear starting point for screen readers,
  // indicating that new content has been loaded.
  useEffect(() => {
    if (contentRef.current) {
      const h1 = contentRef.current.querySelector('h1');
      if (h1) {
        h1.setAttribute('tabindex', '-1');
        h1.style.outline = 'none';
        h1.focus();
      }
    }
  }, [html]);

  return (
    <Container maxWidth="md" sx={{ paddingTop: 2, paddingBottom: 2 }}>
      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          display: 'block',
          margin: '30px',
        }}
      >
        {img ? (
          <ResponsiveLogo imgSrc={img} data-testid="logoImage" />
        ) : null}
      </Box>
      <Box
        component="span"
        data-testid="bodyText"
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        sx={{
          display: 'block',
          margin: '50px',
        }}
        dangerouslySetInnerHTML={{
          __html: transformFirstHtmlElementToH1(html),
        }}
        ref={contentRef}
      />
      <FhxNavButtons
        backAllowed={backAllowed}
        continueAllowed={inputRequired}
        continueButtonCallback={handleContinueClick}
        backButtonCallback={handleBackClick}
        isLoading={loading}
      />
    </Container>
  );
}

FhxText.propTypes = {
  id: PropTypes.number.isRequired,
  html: PropTypes.string,
  img: PropTypes.string,
  inputRequired: PropTypes.bool,
  backAllowed: PropTypes.bool,
  setParentStateCB: PropTypes.func,
};
FhxText.defaultProps = {
  html: '',
  img: '',
  inputRequired: undefined,
  backAllowed: undefined,
  setParentStateCB: () => { },
};

export default FhxText;
