import Cookie from 'universal-cookie';
import * as speechSDK from 'microsoft-cognitiveservices-speech-sdk';

import IntegrationService from './IntegrationServices';

export default class SpeechToTextService {
  constructor() {
    this.integrationService = new IntegrationService();
  }

  async getTokenOrRefresh(mkey) {
    const cookie = new Cookie();
    const speechToken = cookie.get('speech-token');

    if (speechToken === undefined) {
      try {
        const response = await this.integrationService.getSpeechToken(mkey);
        const { token, region } = response.result.data;
        cookie.set('speech-token', `${region}:${token}`, { maxAge: 540, path: '/' });

        return { authToken: token, region };
      } catch (err) {
        return { authToken: null, error: err.response.data };
      }
    } else {
      const speechTokenInfo = speechToken.split(':');
      return { authToken: speechTokenInfo[1], region: speechTokenInfo[0] };
    }
  }

  static getLanguageCode(language) {
    let code;
    switch (language) {
      case 'en':
        code = 'en-US';
        break;
      case 'ar':
        code = 'ar-SA';
        break;
      case 'fr_CA':
        code = 'fr-CA';
        break;
      default:
        code = 'en-US';
        break;
    }
    return code;
  }

  async getSpeechToTextRecognizer(mkey, language) {
    const { authToken, region } = await this.getTokenOrRefresh(mkey);
    const speechConfig = speechSDK.SpeechConfig.fromAuthorizationToken(authToken, region);
    speechConfig.speechRecognitionLanguage = SpeechToTextService.getLanguageCode(language);

    const audioConfig = speechSDK.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new speechSDK.SpeechRecognizer(speechConfig, audioConfig);
    return recognizer;
  }
}
