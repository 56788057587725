import React from 'react';
import PropTypes from 'prop-types';
import { ResultReason, Recognizer } from 'microsoft-cognitiveservices-speech-sdk';

import { Box } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { Controls } from './Controls';
import SpeechToTextService from '../services/STTServices';

const speechToTextService = new SpeechToTextService();

export default function SpeechToTextButton({
  setValue,
  recognizingSpeech,
  setRecognizingSpeech,
  speechRecognizer,
  setSpeechRecognizer,
  language,
}) {
  const enableMicroPhone = async () => {
    const recognizer = await speechToTextService
      .getSpeechToTextRecognizer(global.mkey, language);
    if (recognizer) {
      recognizer.recognized = (s, e) => {
        if (e.result.reason === ResultReason.RecognizedSpeech) {
          setValue((prevState) => (`${prevState} ${e.result.text}`));
        }
      };
      setSpeechRecognizer(recognizer);
      setRecognizingSpeech(true);
      recognizer.startContinuousRecognitionAsync();
    }
  };

  const stopSpeechToTextRecording = () => {
    if (speechRecognizer) {
      setRecognizingSpeech(false);
      speechRecognizer.stopContinuousRecognitionAsync();
    }
  };

  return (
    <Box display="flex" justifyContent="flex-end" my={2}>
      <Controls.Button
        variant="secondary"
        data-testid="enableMic"
        color="primary"
        onClick={
          recognizingSpeech
            ? () => stopSpeechToTextRecording()
            : () => enableMicroPhone()
        }
        text={recognizingSpeech ? 'Stop' : 'Enable Mic'}
        startIcon={recognizingSpeech ? <MicOffIcon /> : <MicIcon />}
      />
      <Controls.Button
        data-testid="clearText"
        text="Clear Text"
        variant="text"
        onClick={() => setValue('')}
      />
    </Box>
  );
}

SpeechToTextButton.propTypes = {
  setValue: PropTypes.func.isRequired,
  recognizingSpeech: PropTypes.bool.isRequired,
  setRecognizingSpeech: PropTypes.func.isRequired,
  speechRecognizer: PropTypes.instanceOf(Recognizer),
  setSpeechRecognizer: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

SpeechToTextButton.defaultProps = {
  speechRecognizer: null,
};
