import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Box, Typography,
} from '@mui/material';
import FhxQuestion from '../FhxQuestion';
import FhxTestDriveControls from './controls/FhxTestDriveControls';

export default function FhxTestDrive({
  mkey, content, setContent, setCustomLocalization, medicationFormType,
}) {
  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="center"
      alignContent="center"
      sx={{ height: 800 }}
    >
      <Grid
        item
        width={600}
        sx={{
          border: 1, borderColor: 'grey.500', mr: 5,
        }}
      >
        <Typography variant="h1" sx={{ ml: 3, my: 1 }} textAlign="center">
          Simulated Patient&apos;s Screen
        </Typography>
        <Box sx={{
          height: 700, width: 500, marginLeft: 6, overflowY: 'auto', border: 1, borderColor: 'grey.500', borderRadius: 10,
        }}
        >
          <FhxQuestion
            content={content}
            setContent={setContent}
            mkey={mkey}
            isTestDrive
            setCustomLocalization={setCustomLocalization}
            medicationFormType={medicationFormType}
          />
        </Box>
      </Grid>
      <Grid
        item
        width={500}
        sx={{ border: 1, borderColor: 'grey.500' }}
      >
        <FhxTestDriveControls
          questionNumber={content.id}
          mkey={global.mkey}
          setContent={setContent}
        />
      </Grid>

    </Grid>

  );
}

FhxTestDrive.propTypes = {
  mkey: PropTypes.string.isRequired,
  content: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  setContent: PropTypes.func.isRequired,
  setCustomLocalization: PropTypes.func.isRequired,
  medicationFormType: PropTypes.string,
};

FhxTestDrive.defaultProps = {
  medicationFormType: '',
};
